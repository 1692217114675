import { AntimicrobialCatService } from './antimicrobial-cat.service';
import { AntimicrobialConfigService } from './antimicrobial-config.service';
import { AntimicrobialExtrasService } from './antimicrobial-extras.service';
import { AntimicrobialProfilaxiaService } from './antimicrobial-profilaxia.service';
import { AntimicrobialInfeccoesService } from './antimicrobial-infeccoes.service';
import { AntimicrobialRenalAdjustService } from './antimicrobial-renaladjust.service';
import { AntimicrobialRenalAdjustGroupService } from 'src/app/core/services/antimicrobial/antimicrobial-renaladjustgroup';

export const ANTIMICROBIAL_SERVICES = [
  AntimicrobialCatService,
  AntimicrobialConfigService,
  AntimicrobialExtrasService,
  AntimicrobialProfilaxiaService,
  AntimicrobialInfeccoesService,
  AntimicrobialRenalAdjustService,
  AntimicrobialRenalAdjustGroupService
];
