import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './calc-items.actions';

import { Navigate } from '@ngxs/router-plugin';
import { CalcItem } from '../models/calc-items.model';
import { CalcItemService } from 'src/app/core/services/calculators/calc-items.service';
import { GroupContentService } from '../../../core/services/contents/groupcontent.service';

export class CalcItemStateModel extends NgxsEntityStateModel<CalcItem> {}

@State<CalcItemStateModel>({
  name: 'calcitems',
  defaults: CalcItemStateModel.InitialState()
})

@Injectable()
export class CalcItemState {

  constructor(private calcItemaService: CalcItemService, private groupService: GroupContentService) { }

  @Selector()
  static entities(state: CalcItemStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: CalcItemStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: CalcItemStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: CalcItemStateModel) {
    return state;
  }

  @Selector()
  static items(state: CalcItemStateModel) {
    return state.items;
    // return Object.keys(state.entities).map(id => state.entities[id]);
  }

  @Selector()
  static count(state: CalcItemStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: CalcItemStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: CalcItemStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: CalcItemStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: CalcItemStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: CalcItemStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  findItem(ctx: StateContext<CalcItemStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    if (action.id === 0) {
      const item: any = { id: 0 };
      NgxsEntityAdapter.setItemSelected(ctx, item);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(item);
    } else {
      return this.calcItemaService.findWithAssociations(action.id).pipe(
      tap(async (item: any) => {
          const filters = [{ fieldName: 'id', matchMode: 'notIn', fieldValue: item.groups.map(p => p.id.toString())}];
          const groupsIN: any = await this.groupService.filters(1000, 0, [], [], [], filters).pipe(take(1)).toPromise();
          console.log('GROUPS IN ', groupsIN);

          NgxsEntityAdapter.setItemSelected(ctx, item);
          NgxsEntityAdapter.setLoading(ctx, false);
          NgxsEntityAdapter.setDualList(ctx, groupsIN.rows, item.groups);
          return of(item);
      }),
      catchError(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      }));
    }
}


  @Action(LoadItems)
  loadItems(ctx: StateContext<CalcItemStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    const includes = [{attributes: ['id', 'name'], model: 'GroupContent', as: 'groups'}];

    return this.calcItemaService.filters(action.limit, action.offset, action.filters,
      action.orders, includes, action.filtersFormated)
    .pipe(
        tap((res: any) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res.rows, res.count);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<CalcItemStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    if (action.item.id === 0) {
      return this.calcItemaService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/calc/items/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.calcItemaService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/calc/items/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }

   }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<CalcItemStateModel>, action: DeleteItem) {
     /* return this.calcItemaService.deletePermWithRoles(action.item).pipe(take(1)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res.decription);
       ctx.dispatch(new Navigate(['dashboard/elements/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     }); */
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<CalcItemStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.calcItemaService.deleteMany(action.items.map(i => i.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/elements/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<CalcItemStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<CalcItemStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
