import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { GroupClass } from 'src/app/dashboard/classes/models/groupclass.model';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class GroupClassService extends GenerichttpService<GroupClass> {

  constructor(protected injector: Injector) {
    super(Constants.GROUPCLASS.toString(), injector, GroupClass.fromJson);
  }

  listWithElements(): Observable<any> {
    return this.http.get(Constants.BASE_URL + 'groupclass/listWithElements').pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

}
