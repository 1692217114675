import { ElementService } from './element.service';
import { GroupClassService } from './groupclasse.service';
import { GroupContentService } from './groupcontent.service';
import { IconService } from './icon.service';

export const CONTENTS_SERVICES = [
  ElementService,
  GroupClassService,
  GroupContentService,
  IconService
];
