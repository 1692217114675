import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Plan } from 'src/app/auth/models/auth.interface';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class PlanService extends GenerichttpService<Plan> {

  constructor(protected injector: Injector) {
    super(Constants.PLAN.toString(), injector, Plan.fromJson);
  }


  findByIdentifier(identifier: string): Observable<Plan> {
    console.log('ENTROU EM FIND IDENTIFIER', identifier);
    return this.http.get(`${Constants.endPoint(this.apiPath, '/findByIdentifier')}/${identifier}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

}
