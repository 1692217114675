import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { AntiMicrobialExtras } from 'src/app/dashboard/microbial/models/microbial.model';

@Injectable({
  providedIn: 'root'
})

export class AntimicrobialExtrasService extends GenerichttpService<AntiMicrobialExtras> {

  constructor(protected injector: Injector) {
    super(Constants.ANTIMICROBIAL_EXTRAS.toString(), injector, AntiMicrobialExtras.fromJson);
  }

}
