import { Injectable} from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AuthDevice } from '../../../auth/models/auth.interface';
const { Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  device: AuthDevice;


  constructor() {
    this.getDeviceInfo();
  }

  async getDeviceInfo(): Promise<AuthDevice> {
    const info = await Device.getInfo();
    // console.log('*** DEVICE INFO ** ', info);
    this.device = {
      id: 0,
      model: info.model,
      key: info.uuid,
      os: info.platform,
      gateway: 'NONE',
      subscription: 'DEACTIVE',
      credentialId: 0
    };
    return this.device;
  }



}
