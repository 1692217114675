import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { BularEspecialidade } from 'src/app/dashboard/bulario/models/bular.model';

@Injectable({
  providedIn: 'root'
})

export class BularEspecService extends GenerichttpService<BularEspecialidade> {

  constructor(protected injector: Injector) {
    super(Constants.BULAR_ESPEC.toString(), injector, BularEspecialidade.fromJson);
  }

}
