import { BaseResource } from 'src/app/formly/models/basersource.model';

export class SignupPlanClassesModel extends BaseResource {
  credential: {
      id: number;
      password: string;
      username: string;
      firstName: string;
      lastName: string;
      email: string;
      isActive: boolean;
  };
  customer: {
      id: number;
      govId: string;
      gender: string;
      picture: string;
      phone: string;
      birthDate: string;
  };
  address: any;
  subItems: SubItem[];
  plan: {
    id: number;
    name: string;
    identifier: string;
    price: number;
    description: string;
    intervalType: string;
  };


  static fromJson(jsonData: any): SignupPlanClassesModel {
    return Object.assign(new SignupPlanClassesModel(), jsonData);
  }
}



export class SubItem extends BaseResource {
  id: number;
  description: string;
  priceCents: number;
  quantity: number;
  recurrent: boolean;

  static fromJson(jsonData: any): SubItem {
    return Object.assign(new SubItem(), jsonData);
  }
}

export class Credential extends BaseResource {
  id: number;
  password: string;
  lastLogin: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;

static fromJson(jsonData: any): Credential {
  return Object.assign(new Credential(), jsonData);
}
}

export class SignUpModel extends BaseResource {
  credential: Credential;
  roleId: number;
  phoneNumber: string;

  static fromJson(jsonData: any): SignUpModel {
    return Object.assign(new SignUpModel(), jsonData);
  }
}

export class Assinar extends BaseResource {
  credential: Credential;
  customer: Customer;
  planIdentifier: string;

  static fromJson(jsonData: any): Assinar {
    return Object.assign(new Assinar(), jsonData);
  }
}

export class Autorization {
  constructor(
    public token?: string,
    public role?: string,
    public account?: string,
    public user?: string,
    public picture?: string
  ) {
  }

  static fromJson(jsonData: any): Autorization {
    return Object.assign(new Autorization(), jsonData);
  }
}

export interface AuthDevice {
  id?: number;
  key: string;
  model: string;
  os: string;
  gateway?: string;
  subscription?: string;
  credentialId: number;
  oneSignalId?: string;
}

export class AuthDevice extends BaseResource {
  id?: number;
  key: string;
  model: string;
  os: string;
  gateway?: string;
  subscription?: string;
  credentialId: number;
  oneSignalId?: string;

  static fromJson(jsonData: any): AuthDevice {
    return Object.assign(new AuthDevice(), jsonData);
  }
}

export class DeviceFamily extends BaseResource {
  titularId: number;
  deviceId: number;
  dependentId: number;
  device?: AuthDevice;

  static fromJson(jsonData: any): DeviceFamily {
    return Object.assign(new DeviceFamily(), jsonData);
  }
}

export class Country extends BaseResource {
  id: number;
  name: string;
  code: string;
  dialCode: string;

  static fromJson(jsonData: any): Country {
    return Object.assign(new Country(), jsonData);
  }
}

export class Address extends BaseResource {
  id: number;
  street: string;
  addressNumber: string;
  zipCode: string;
  neighborhood?: string;
  uf?: string;
  city?: string;
  complement?: string;

  static fromJson(jsonData: any): Address {
    return Object.assign(new Address(), jsonData);
  }
}

export interface Address {
  id: number;
  street: string;
  addressNumber: string;
  zipCode: string;
  neighborhood?: string;
  uf?: string;
  city?: string;
  complement?: string;
}

export interface Profile {
  credential: Credential;
  customer: Customer;
  address: Address;
}


export interface User {
  id?: number;
  nome: string;
  email: string;
  password: string;
  imagem?: string;
  empresaId: number;
  termosUso: boolean;
  politicaPrivacidade: boolean;
  dispositivos?: any[]; // dispositivos?: Dispositivo[];
  roleId?: number;
  }

export class Auth extends BaseResource {
  token: string;
  tokenType: string;
  type: string;
  username: string;
  roles: Role[];
  permissions: Permission[];

  static fromJson(jsonData: any): Auth {
    return Object.assign(new Auth(), jsonData);
  }
}


export interface UserToken {
  // user: User;
  token: string;
  token_type: string;
  type: string;
  username: string;
  firstName: string;
  roles: Role[];
  permissions: Permission[];
}

export interface Dispositivo {
  id?: number;
  token: string;
  uuid: string;
  model: string;
  cordova: string;
  platform: string;
  version: string;
  serial: string;
  identificador: string;
  usuarioId: number;
  }


/**** PERMISSOES *******/
export enum PERMISSOES {
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  VIEW_USER = 'VIEW_USER',
  VIEW_LIST_USERS = 'VIEW_LIST_USERS',
  TORNAR_ADMIN = 'TORNAR_ADMIN',
  CREATE_EVENTO = 'CREATE_EVENTO',
  UPDATE_EVENTO = 'UPDATE_EVENTO',
  DELETE_EVENTO = 'DELETE_EVENTO',
}

export enum ROLES {
  ADMIN = 1,
  MEMBRO = 2,
  VISITANTE = 3
}

export enum TOKEN_TYPE {
  TOKEN = 'TOKEN',
  DEVICE = 'DEVICE',
  TWO_FACTOR = 'TWO_FACTOR',
  CREATE_FACTOR = 'CREATE_FACTOR'
}



export class Permission extends BaseResource {
  id: number;
  name: string;
  description: string;
  roles?: Role[];

  static fromJson(jsonData: any): Permission {
    return Object.assign(new Permission(), jsonData);
  }
}


export class Role extends BaseResource {
  id: number;
  name: string;
  description: string;
  abbreviation: string;
  permissions?: Permission[];

  static fromJson(jsonData: any): Role {
    return Object.assign(new Role(), jsonData);
  }
}


export class Customer extends BaseResource {
  id: number;
  govId: string;
  gender: string;
  occupation: string;
  picture: string;
  phone: string;
  birthDate: string;
  iuguId: string;
  notificationsRead: number;
  addressId: number;
  address: any;
  credentialId: number;
  credential: Credential;

  createAt: string;
  updatedAt: string;
  deletedAt: string;


  static fromJson(jsonData: any): Customer {
    return Object.assign(new Customer(), jsonData);
  }
}


export class Plan extends BaseResource {
  id: number;
  name: string;
  description: string;
  price: number;
  order: number;
  identifier: string;
  intervalType: string;
  deviceType: any[];
  qtdDevices?: number;
  isFeatured: boolean;
  priceFull: number;
  priceDiscount: number;
  pricePercentage: number;
  roles: Role[];
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): Plan {
    return Object.assign(new Plan(), jsonData);
  }
}



export class SubscriptionModel extends BaseResource {
  id: number;
  status: number;
  gateway: string;
  planIdentifier: string;
  plan: Plan;
  createdOn?: string;

  static fromJson(jsonData: any): Country {
    return Object.assign(new Country(), jsonData);
  }
}

export const ENUM_GENDER = [
  { label: 'Masculino', value: 'M'},
  { label: 'Feminino', value: 'F'},
];


export const AREA_INTERESSE = [
  {
    value : 1,
    label : "Acadêmico de medicina"
  },
  {
    value: 2,
    label : "Médico",
    "description" : "Médico"
  },
  {
    value : 3,
    label : "Acadêmico de Enfermagem / Enfermeiro",
    "description" : "Acadêmico de Enfermagem / Enfermeiro"
  },
  {
    value : 4,
    label : "Outro profissional da saúde",
    "description" : "Outro profissional da saúde"
  }
]

export const COUNTRIES = [
	{
		"id" : 1,
		"code" : "IL",
		"dial_code" : "+972",
		"name" : "Israel",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 2,
		"code" : "AF",
		"dial_code" : "+93",
		"name" : "Afghanistan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 3,
		"code" : "AL",
		"dial_code" : "+355",
		"name" : "Albania",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 4,
		"code" : "DZ",
		"dial_code" : "+213",
		"name" : "Algeria",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 5,
		"code" : "AS",
		"dial_code" : "+1 684",
		"name" : "AmericanSamoa",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 6,
		"code" : "AD",
		"dial_code" : "+376",
		"name" : "Andorra",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 7,
		"code" : "AO",
		"dial_code" : "+244",
		"name" : "Angola",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 8,
		"code" : "AI",
		"dial_code" : "+1 264",
		"name" : "Anguilla",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 9,
		"code" : "AG",
		"dial_code" : "+1268",
		"name" : "Antigua and Barbuda",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 10,
		"code" : "AR",
		"dial_code" : "+54",
		"name" : "Argentina",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 11,
		"code" : "AM",
		"dial_code" : "+374",
		"name" : "Armenia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 12,
		"code" : "AW",
		"dial_code" : "+297",
		"name" : "Aruba",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 13,
		"code" : "AU",
		"dial_code" : "+61",
		"name" : "Australia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 14,
		"code" : "AT",
		"dial_code" : "+43",
		"name" : "Austria",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 15,
		"code" : "AZ",
		"dial_code" : "+994",
		"name" : "Azerbaijan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 16,
		"code" : "BS",
		"dial_code" : "+1 242",
		"name" : "Bahamas",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 17,
		"code" : "BH",
		"dial_code" : "+973",
		"name" : "Bahrain",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 18,
		"code" : "BD",
		"dial_code" : "+880",
		"name" : "Bangladesh",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 19,
		"code" : "BB",
		"dial_code" : "+1 246",
		"name" : "Barbados",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 20,
		"code" : "BY",
		"dial_code" : "+375",
		"name" : "Belarus",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 21,
		"code" : "BE",
		"dial_code" : "+32",
		"name" : "Belgium",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 22,
		"code" : "BZ",
		"dial_code" : "+501",
		"name" : "Belize",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 23,
		"code" : "BJ",
		"dial_code" : "+229",
		"name" : "Benin",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 24,
		"code" : "BM",
		"dial_code" : "+1 441",
		"name" : "Bermuda",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 25,
		"code" : "BT",
		"dial_code" : "+975",
		"name" : "Bhutan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 26,
		"code" : "BA",
		"dial_code" : "+387",
		"name" : "Bosnia and Herzegovina",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 27,
		"code" : "BW",
		"dial_code" : "+267",
		"name" : "Botswana",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 28,
		"code" : "BR",
		"dial_code" : "+55",
		"name" : "Brasil",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 29,
		"code" : "IO",
		"dial_code" : "+246",
		"name" : "British Indian Ocean Territory",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 30,
		"code" : "BG",
		"dial_code" : "+359",
		"name" : "Bulgaria",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 31,
		"code" : "BF",
		"dial_code" : "+226",
		"name" : "Burkina Faso",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 32,
		"code" : "BI",
		"dial_code" : "+257",
		"name" : "Burundi",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 33,
		"code" : "KH",
		"dial_code" : "+855",
		"name" : "Cambodia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 34,
		"code" : "CM",
		"dial_code" : "+237",
		"name" : "Cameroon",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 35,
		"code" : "CA",
		"dial_code" : "+1",
		"name" : "Canada",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 36,
		"code" : "CV",
		"dial_code" : "+238",
		"name" : "Cape Verde",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 37,
		"code" : "KY",
		"dial_code" : "+ 345",
		"name" : "Cayman Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 38,
		"code" : "CF",
		"dial_code" : "+236",
		"name" : "Central African Republic",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 39,
		"code" : "TD",
		"dial_code" : "+235",
		"name" : "Chad",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 40,
		"code" : "CL",
		"dial_code" : "+56",
		"name" : "Chile",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 41,
		"code" : "CN",
		"dial_code" : "+86",
		"name" : "China",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 42,
		"code" : "CX",
		"dial_code" : "+61",
		"name" : "Christmas Island",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 43,
		"code" : "CO",
		"dial_code" : "+57",
		"name" : "Colombia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 44,
		"code" : "KM",
		"dial_code" : "+269",
		"name" : "Comoros",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 45,
		"code" : "CG",
		"dial_code" : "+242",
		"name" : "Congo",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 46,
		"code" : "CK",
		"dial_code" : "+682",
		"name" : "Cook Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 47,
		"code" : "CR",
		"dial_code" : "+506",
		"name" : "Costa Rica",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 48,
		"code" : "HR",
		"dial_code" : "+385",
		"name" : "Croatia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 49,
		"code" : "CU",
		"dial_code" : "+53",
		"name" : "Cuba",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 50,
		"code" : "CY",
		"dial_code" : "+537",
		"name" : "Cyprus",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 51,
		"code" : "CZ",
		"dial_code" : "+420",
		"name" : "Czech Republic",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 52,
		"code" : "DK",
		"dial_code" : "+45",
		"name" : "Denmark",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 53,
		"code" : "DJ",
		"dial_code" : "+253",
		"name" : "Djibouti",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 54,
		"code" : "DM",
		"dial_code" : "+1 767",
		"name" : "Dominica",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 55,
		"code" : "DO",
		"dial_code" : "+1 849",
		"name" : "Dominican Republic",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 56,
		"code" : "EC",
		"dial_code" : "+593",
		"name" : "Ecuador",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 57,
		"code" : "EG",
		"dial_code" : "+20",
		"name" : "Egypt",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 58,
		"code" : "SV",
		"dial_code" : "+503",
		"name" : "El Salvador",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 59,
		"code" : "GQ",
		"dial_code" : "+240",
		"name" : "Equatorial Guinea",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 60,
		"code" : "ER",
		"dial_code" : "+291",
		"name" : "Eritrea",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 61,
		"code" : "EE",
		"dial_code" : "+372",
		"name" : "Estonia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 62,
		"code" : "ET",
		"dial_code" : "+251",
		"name" : "Ethiopia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 63,
		"code" : "FO",
		"dial_code" : "+298",
		"name" : "Faroe Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 64,
		"code" : "FJ",
		"dial_code" : "+679",
		"name" : "Fiji",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 65,
		"code" : "FI",
		"dial_code" : "+358",
		"name" : "Finland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 66,
		"code" : "FR",
		"dial_code" : "+33",
		"name" : "France",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 67,
		"code" : "GF",
		"dial_code" : "+594",
		"name" : "French Guiana",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 68,
		"code" : "PF",
		"dial_code" : "+689",
		"name" : "French Polynesia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 69,
		"code" : "GA",
		"dial_code" : "+241",
		"name" : "Gabon",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 70,
		"code" : "GM",
		"dial_code" : "+220",
		"name" : "Gambia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 71,
		"code" : "GE",
		"dial_code" : "+995",
		"name" : "Georgia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 72,
		"code" : "DE",
		"dial_code" : "+49",
		"name" : "Germany",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 73,
		"code" : "GH",
		"dial_code" : "+233",
		"name" : "Ghana",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 74,
		"code" : "GI",
		"dial_code" : "+350",
		"name" : "Gibraltar",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 75,
		"code" : "GR",
		"dial_code" : "+30",
		"name" : "Greece",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 76,
		"code" : "GL",
		"dial_code" : "+299",
		"name" : "Greenland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 77,
		"code" : "GD",
		"dial_code" : "+1 473",
		"name" : "Grenada",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 78,
		"code" : "GP",
		"dial_code" : "+590",
		"name" : "Guadeloupe",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 79,
		"code" : "GU",
		"dial_code" : "+1 671",
		"name" : "Guam",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 80,
		"code" : "GT",
		"dial_code" : "+502",
		"name" : "Guatemala",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 81,
		"code" : "GN",
		"dial_code" : "+224",
		"name" : "Guinea",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 82,
		"code" : "GW",
		"dial_code" : "+245",
		"name" : "Guinea-Bissau",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 83,
		"code" : "GY",
		"dial_code" : "+595",
		"name" : "Guyana",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 84,
		"code" : "HT",
		"dial_code" : "+509",
		"name" : "Haiti",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 85,
		"code" : "HN",
		"dial_code" : "+504",
		"name" : "Honduras",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 86,
		"code" : "HU",
		"dial_code" : "+36",
		"name" : "Hungary",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 87,
		"code" : "IS",
		"dial_code" : "+354",
		"name" : "Iceland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 88,
		"code" : "IN",
		"dial_code" : "+91",
		"name" : "India",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 89,
		"code" : "ID",
		"dial_code" : "+62",
		"name" : "Indonesia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 90,
		"code" : "IQ",
		"dial_code" : "+964",
		"name" : "Iraq",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 91,
		"code" : "IE",
		"dial_code" : "+353",
		"name" : "Ireland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 92,
		"code" : "IL",
		"dial_code" : "+972",
		"name" : "Israel",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 93,
		"code" : "IT",
		"dial_code" : "+39",
		"name" : "Italy",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 94,
		"code" : "JM",
		"dial_code" : "+1 876",
		"name" : "Jamaica",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 95,
		"code" : "JP",
		"dial_code" : "+81",
		"name" : "Japan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 96,
		"code" : "JO",
		"dial_code" : "+962",
		"name" : "Jordan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 97,
		"code" : "KZ",
		"dial_code" : "+7 7",
		"name" : "Kazakhstan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 98,
		"code" : "KE",
		"dial_code" : "+254",
		"name" : "Kenya",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 99,
		"code" : "KI",
		"dial_code" : "+686",
		"name" : "Kiribati",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 100,
		"code" : "KW",
		"dial_code" : "+965",
		"name" : "Kuwait",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 101,
		"code" : "KG",
		"dial_code" : "+996",
		"name" : "Kyrgyzstan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 102,
		"code" : "LV",
		"dial_code" : "+371",
		"name" : "Latvia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 103,
		"code" : "LB",
		"dial_code" : "+961",
		"name" : "Lebanon",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 104,
		"code" : "LS",
		"dial_code" : "+266",
		"name" : "Lesotho",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 105,
		"code" : "LR",
		"dial_code" : "+231",
		"name" : "Liberia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 106,
		"code" : "LI",
		"dial_code" : "+423",
		"name" : "Liechtenstein",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 107,
		"code" : "LT",
		"dial_code" : "+370",
		"name" : "Lithuania",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 108,
		"code" : "LU",
		"dial_code" : "+352",
		"name" : "Luxembourg",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 109,
		"code" : "MG",
		"dial_code" : "+261",
		"name" : "Madagascar",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 110,
		"code" : "MW",
		"dial_code" : "+265",
		"name" : "Malawi",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 111,
		"code" : "MY",
		"dial_code" : "+60",
		"name" : "Malaysia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 112,
		"code" : "MV",
		"dial_code" : "+960",
		"name" : "Maldives",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 113,
		"code" : "ML",
		"dial_code" : "+223",
		"name" : "Mali",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 114,
		"code" : "MT",
		"dial_code" : "+356",
		"name" : "Malta",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 115,
		"code" : "MH",
		"dial_code" : "+692",
		"name" : "Marshall Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 116,
		"code" : "MQ",
		"dial_code" : "+596",
		"name" : "Martinique",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 117,
		"code" : "MR",
		"dial_code" : "+222",
		"name" : "Mauritania",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 118,
		"code" : "MU",
		"dial_code" : "+230",
		"name" : "Mauritius",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 119,
		"code" : "YT",
		"dial_code" : "+262",
		"name" : "Mayotte",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 120,
		"code" : "MX",
		"dial_code" : "+52",
		"name" : "Mexico",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 121,
		"code" : "MC",
		"dial_code" : "+377",
		"name" : "Monaco",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 122,
		"code" : "MN",
		"dial_code" : "+976",
		"name" : "Mongolia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 123,
		"code" : "ME",
		"dial_code" : "+382",
		"name" : "Montenegro",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 124,
		"code" : "MS",
		"dial_code" : "+1664",
		"name" : "Montserrat",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 125,
		"code" : "MA",
		"dial_code" : "+212",
		"name" : "Morocco",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 126,
		"code" : "MM",
		"dial_code" : "+95",
		"name" : "Myanmar",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 127,
		"code" : "NA",
		"dial_code" : "+264",
		"name" : "Namibia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 128,
		"code" : "NR",
		"dial_code" : "+674",
		"name" : "Nauru",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 129,
		"code" : "NP",
		"dial_code" : "+977",
		"name" : "Nepal",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 130,
		"code" : "NL",
		"dial_code" : "+31",
		"name" : "Netherlands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 131,
		"code" : "AN",
		"dial_code" : "+599",
		"name" : "Netherlands Antilles",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 132,
		"code" : "NC",
		"dial_code" : "+687",
		"name" : "New Caledonia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 133,
		"code" : "NZ",
		"dial_code" : "+64",
		"name" : "New Zealand",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 134,
		"code" : "NI",
		"dial_code" : "+505",
		"name" : "Nicaragua",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 135,
		"code" : "NE",
		"dial_code" : "+227",
		"name" : "Niger",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 136,
		"code" : "NG",
		"dial_code" : "+234",
		"name" : "Nigeria",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 137,
		"code" : "NU",
		"dial_code" : "+683",
		"name" : "Niue",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 138,
		"code" : "NF",
		"dial_code" : "+672",
		"name" : "Norfolk Island",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 139,
		"code" : "MP",
		"dial_code" : "+1 670",
		"name" : "Northern Mariana Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 140,
		"code" : "NO",
		"dial_code" : "+47",
		"name" : "Norway",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 141,
		"code" : "OM",
		"dial_code" : "+968",
		"name" : "Oman",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 142,
		"code" : "PK",
		"dial_code" : "+92",
		"name" : "Pakistan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 143,
		"code" : "PW",
		"dial_code" : "+680",
		"name" : "Palau",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 144,
		"code" : "PA",
		"dial_code" : "+507",
		"name" : "Panama",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 145,
		"code" : "PG",
		"dial_code" : "+675",
		"name" : "Papua New Guinea",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 146,
		"code" : "PY",
		"dial_code" : "+595",
		"name" : "Paraguay",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 147,
		"code" : "PE",
		"dial_code" : "+51",
		"name" : "Peru",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 148,
		"code" : "PH",
		"dial_code" : "+63",
		"name" : "Philippines",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 149,
		"code" : "PL",
		"dial_code" : "+48",
		"name" : "Poland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 150,
		"code" : "PT",
		"dial_code" : "+351",
		"name" : "Portugal",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 151,
		"code" : "PR",
		"dial_code" : "+1 939",
		"name" : "Puerto Rico",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 152,
		"code" : "QA",
		"dial_code" : "+974",
		"name" : "Qatar",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 153,
		"code" : "RO",
		"dial_code" : "+40",
		"name" : "Romania",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 154,
		"code" : "RW",
		"dial_code" : "+250",
		"name" : "Rwanda",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 155,
		"code" : "WS",
		"dial_code" : "+685",
		"name" : "Samoa",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 156,
		"code" : "SM",
		"dial_code" : "+378",
		"name" : "San Marino",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 157,
		"code" : "SA",
		"dial_code" : "+966",
		"name" : "Saudi Arabia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 158,
		"code" : "SN",
		"dial_code" : "+221",
		"name" : "Senegal",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 159,
		"code" : "RS",
		"dial_code" : "+381",
		"name" : "Serbia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 160,
		"code" : "SC",
		"dial_code" : "+248",
		"name" : "Seychelles",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 161,
		"code" : "SL",
		"dial_code" : "+232",
		"name" : "Sierra Leone",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 162,
		"code" : "SG",
		"dial_code" : "+65",
		"name" : "Singapore",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 163,
		"code" : "SK",
		"dial_code" : "+421",
		"name" : "Slovakia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 164,
		"code" : "SI",
		"dial_code" : "+386",
		"name" : "Slovenia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 165,
		"code" : "SB",
		"dial_code" : "+677",
		"name" : "Solomon Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 166,
		"code" : "ZA",
		"dial_code" : "+27",
		"name" : "South Africa",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 167,
		"code" : "GS",
		"dial_code" : "+500",
		"name" : "South Georgia and the South Sandwich Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 168,
		"code" : "ES",
		"dial_code" : "+34",
		"name" : "Spain",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 169,
		"code" : "LK",
		"dial_code" : "+94",
		"name" : "Sri Lanka",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 170,
		"code" : "SD",
		"dial_code" : "+249",
		"name" : "Sudan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 171,
		"code" : "SR",
		"dial_code" : "+597",
		"name" : "Suriname",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 172,
		"code" : "SZ",
		"dial_code" : "+268",
		"name" : "Swaziland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 173,
		"code" : "SE",
		"dial_code" : "+46",
		"name" : "Sweden",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 174,
		"code" : "CH",
		"dial_code" : "+41",
		"name" : "Switzerland",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 175,
		"code" : "TJ",
		"dial_code" : "+992",
		"name" : "Tajikistan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 176,
		"code" : "TH",
		"dial_code" : "+66",
		"name" : "Thailand",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 177,
		"code" : "TG",
		"dial_code" : "+228",
		"name" : "Togo",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 178,
		"code" : "TK",
		"dial_code" : "+690",
		"name" : "Tokelau",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 179,
		"code" : "TO",
		"dial_code" : "+676",
		"name" : "Tonga",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 180,
		"code" : "TT",
		"dial_code" : "+1 868",
		"name" : "Trinidad and Tobago",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 181,
		"code" : "TN",
		"dial_code" : "+216",
		"name" : "Tunisia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 182,
		"code" : "TR",
		"dial_code" : "+90",
		"name" : "Turkey",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 183,
		"code" : "TM",
		"dial_code" : "+993",
		"name" : "Turkmenistan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 184,
		"code" : "TC",
		"dial_code" : "+1 649",
		"name" : "Turks and Caicos Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 185,
		"code" : "TV",
		"dial_code" : "+688",
		"name" : "Tuvalu",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 186,
		"code" : "UG",
		"dial_code" : "+256",
		"name" : "Uganda",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 187,
		"code" : "UA",
		"dial_code" : "+380",
		"name" : "Ukraine",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 188,
		"code" : "AE",
		"dial_code" : "+971",
		"name" : "United Arab Emirates",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 189,
		"code" : "GB",
		"dial_code" : "+44",
		"name" : "United Kingdom",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 190,
		"code" : "US",
		"dial_code" : "+1",
		"name" : "United States",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 191,
		"code" : "UY",
		"dial_code" : "+598",
		"name" : "Uruguay",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 192,
		"code" : "UZ",
		"dial_code" : "+998",
		"name" : "Uzbekistan",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 193,
		"code" : "VU",
		"dial_code" : "+678",
		"name" : "Vanuatu",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 194,
		"code" : "WF",
		"dial_code" : "+681",
		"name" : "Wallis and Futuna",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 195,
		"code" : "YE",
		"dial_code" : "+967",
		"name" : "Yemen",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 196,
		"code" : "ZM",
		"dial_code" : "+260",
		"name" : "Zambia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 197,
		"code" : "ZW",
		"dial_code" : "+263",
		"name" : "Zimbabwe",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 198,
		"code" : "AX",
		"dial_code" : "",
		"name" : "land Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 199,
		"code" : "AQ",
		"dial_code" : "None",
		"name" : "Antarctica",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 200,
		"code" : "BO",
		"dial_code" : "+591",
		"name" : "Bolivia, Plurinational State of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 201,
		"code" : "BN",
		"dial_code" : "+673",
		"name" : "Brunei Darussalam",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 202,
		"code" : "CC",
		"dial_code" : "+61",
		"name" : "Cocos (Keeling) Islands",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 203,
		"code" : "CD",
		"dial_code" : "+243",
		"name" : "Congo, The Democratic Republic of the",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 204,
		"code" : "CI",
		"dial_code" : "+225",
		"name" : "Cote d'Ivoire",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 205,
		"code" : "FK",
		"dial_code" : "+500",
		"name" : "Falkland Islands (Malvinas)",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 206,
		"code" : "GG",
		"dial_code" : "+44",
		"name" : "Guernsey",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 207,
		"code" : "VA",
		"dial_code" : "+379",
		"name" : "Holy See (Vatican City State)",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 208,
		"code" : "HK",
		"dial_code" : "+852",
		"name" : "Hong Kong",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 209,
		"code" : "IR",
		"dial_code" : "+98",
		"name" : "Iran, Islamic Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 210,
		"code" : "IM",
		"dial_code" : "+44",
		"name" : "Isle of Man",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 211,
		"code" : "JE",
		"dial_code" : "+44",
		"name" : "Jersey",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 212,
		"code" : "KP",
		"dial_code" : "+850",
		"name" : "Korea, Democratic People's Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 213,
		"code" : "KR",
		"dial_code" : "+82",
		"name" : "Korea, Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 214,
		"code" : "LA",
		"dial_code" : "+856",
		"name" : "Lao People's Democratic Republic",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 215,
		"code" : "LY",
		"dial_code" : "+218",
		"name" : "Libyan Arab Jamahiriya",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 216,
		"code" : "MO",
		"dial_code" : "+853",
		"name" : "Macao",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 217,
		"code" : "MK",
		"dial_code" : "+389",
		"name" : "Macedonia, The Former Yugoslav Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 218,
		"code" : "FM",
		"dial_code" : "+691",
		"name" : "Micronesia, Federated States of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 219,
		"code" : "MD",
		"dial_code" : "+373",
		"name" : "Moldova, Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 220,
		"code" : "MZ",
		"dial_code" : "+258",
		"name" : "Mozambique",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 221,
		"code" : "PS",
		"dial_code" : "+970",
		"name" : "Palestinian Territory, Occupied",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 222,
		"code" : "PN",
		"dial_code" : "+872",
		"name" : "Pitcairn",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 223,
		"code" : "RE",
		"dial_code" : "+262",
		"name" : "Réunion",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 224,
		"code" : "RU",
		"dial_code" : "+7",
		"name" : "Russia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 225,
		"code" : "BL",
		"dial_code" : "+590",
		"name" : "Saint Barthélemy",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 226,
		"code" : "SH",
		"dial_code" : "+290",
		"name" : "Saint Helena, Ascension and Tristan Da Cunha",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 227,
		"code" : "KN",
		"dial_code" : "+1 869",
		"name" : "Saint Kitts and Nevis",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 228,
		"code" : "LC",
		"dial_code" : "+1 758",
		"name" : "Saint Lucia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 229,
		"code" : "MF",
		"dial_code" : "+590",
		"name" : "Saint Martin",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 230,
		"code" : "PM",
		"dial_code" : "+508",
		"name" : "Saint Pierre and Miquelon",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 231,
		"code" : "VC",
		"dial_code" : "+1 784",
		"name" : "Saint Vincent and the Grenadines",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 232,
		"code" : "ST",
		"dial_code" : "+239",
		"name" : "Sao Tome and Principe",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 233,
		"code" : "SO",
		"dial_code" : "+252",
		"name" : "Somalia",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 234,
		"code" : "SJ",
		"dial_code" : "+47",
		"name" : "Svalbard and Jan Mayen",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 235,
		"code" : "SY",
		"dial_code" : "+963",
		"name" : "Syrian Arab Republic",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 236,
		"code" : "TW",
		"dial_code" : "+886",
		"name" : "Taiwan, Province of China",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 237,
		"code" : "TZ",
		"dial_code" : "+255",
		"name" : "Tanzania, United Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 238,
		"code" : "TL",
		"dial_code" : "+670",
		"name" : "Timor-Leste",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 239,
		"code" : "VE",
		"dial_code" : "+58",
		"name" : "Venezuela, Bolivarian Republic of",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 240,
		"code" : "VN",
		"dial_code" : "+84",
		"name" : "Viet Nam",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 241,
		"code" : "VG",
		"dial_code" : "+1 284",
		"name" : "Virgin Islands, British",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	},
	{
		"id" : 242,
		"code" : "VI",
		"dial_code" : "+1 340",
		"name" : "Virgin Islands, U.S.",
		"created_at" : null,
		"updated_at" : null,
		"delete_at" : null
	}
];
