import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/auth/store/auth.state';
import { Store } from '@ngxs/store';
import { TOKEN_TYPE } from 'src/app/auth/models/auth.interface';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class DeviceGuard implements CanActivate {

  constructor(private store: Store) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    const tokenType = this.store.selectSnapshot(AuthState.tokenType);
    console.log('tokenType', tokenType);
    if (tokenType !== TOKEN_TYPE.DEVICE) { this.store.dispatch(new Navigate(['auth/signin'])); }

    return true;
  }
}
