import { BaseResource } from 'src/app/formly/models/basersource.model';
import { Element } from '../../elements/models/element.model';
import { CalcItem } from '../../calculators/models/calc-items.model';

export class GroupContent extends BaseResource {
  id: number;
  name: string;
  slug: string;
  icon?: string | null;
  color?: string | null;
  parentId?: number | null;
  groupParent?: GroupContent | null;
  order: number;
  lastUpdate: string | null;
  status: number;
  iconBase64: string;
  length: number;
  isVisible: boolean;
  isFree: boolean;
  groups?: GroupContent[];
  elements?: Element[];
  calculators?: CalcItem[];
  type: string;
  createAt: string;
  updatedAt: string;
  deletedAt: string;


  static fromJson(jsonData: any): GroupContent {
    return Object.assign(new GroupContent(), jsonData);
  }

}

export const ATTRIBUTES_GROUPCONTENT = ['id', 'name', 'slug', 'color', 'order', 'icon', 'status', 'length', 'isVisible', 'parentId'];

export const TABLE_GROUPCONTENT = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'icon', value: 'icon', type: 'string', showTable: true},
  { label: 'color', value: 'color', type: 'string'},
  { label: 'order', value: 'order', type: 'number', showTable: false},
  { label: 'lastUpdate', value: 'lastUpdate', type: 'date'},
  { label: 'status', value: 'status', type: 'number', showTable: true},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'isFree', value: 'isFree', type: 'boolean'},
  { label: 'parentId', value: 'parentId', type: 'number' },
  { label: 'iconBase64', value: 'iconBase64', type: 'string'},
  { label: 'isVisible', value: 'isVisible', type: 'boolean'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
