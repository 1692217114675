import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import { STORE_CONFIG } from './store.config';
import { environment } from 'src/environments/environment.prod';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { AuthState } from '../auth/store/auth.state';

@NgModule({
  imports: [
    NgxsModule.forRoot(STORE_CONFIG, { developmentMode: !environment.production}),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: AuthState }),
    NgxsDispatchPluginModule.forRoot(),

  ],
  exports: [NgxsModule],
  declarations: [],
  providers: [STORE_CONFIG],
})
export class StoreModule {
}
