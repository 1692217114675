import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { AntiMicrobialConfig } from 'src/app/dashboard/microbial/models/microbial.model';

@Injectable({
  providedIn: 'root'
})

export class AntimicrobialConfigService extends GenerichttpService<AntiMicrobialConfig> {

  constructor(protected injector: Injector) {
    super(Constants.ANTIMICROBIAL_CONFIG.toString(), injector, AntiMicrobialConfig.fromJson);
  }

}
