
export class NgxsEntityStateModel<T> {
  public ids: string[];
  public entities: { [id: string]: T };
  public itemSelected: T | null;
  public itemsSelected: T[] | null;

  public items: T[] | null;
  public count: number | 0;
  public error: string | null;
  public loading: boolean | null;
  public success: string | null;

  public dualListFrom?: T[] | null;
  public dualListTarget?: T[] | null;

  static InitialState() {
    return {
      ids: [],
      entities: {},
      itemSelected: null,
      itemsSelected: [],
      items: [],
      count: 0,
      error: null,
      loading: false,
      success: null,
      dualListFrom: [],
      dualListTarget: []
    };
  }
}
