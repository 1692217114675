import { Filter, Order, Include } from 'src/app/formly/models/filters.model';
import { Site } from '../models/site.model';

export class LoadItems {
  static readonly type = '[Site] LoadItems';
  constructor(
    public limit: number, public offset: number,
    public filters: Filter[], public orders: Order[],
    public inclues?: Include[], public filtersFormated?: Filter[]) {}
}

export class FindItem {
  static readonly type = '[Site] FindItem';
  constructor(public id: number) {}
}

export class MigrarPosts {
  static readonly type = '[Site] MigrarPosts';
}

export class AddEditItem {
  static readonly type = '[Site] AddEditItem';
  constructor(public item: Site) {}
}

export class EditItem {
  static readonly type = '[Site] EditItem';
  constructor(public item: Site) {}
}

export class SetLoading {
  static readonly type = '[Site] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[Site] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[Site] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[Site] SetSuccess';
  constructor(public msg: string) {}
}

export class SetItemActive {
  static readonly type = '[Site] SetItemActive';
  constructor(public item: Site) {}
}

export class SetItemsSelected {
  static readonly type = '[Site] SetItemsSelected';
  constructor(public items: Site[]) {}
}

export class DeleteItems {
  static readonly type = '[Site] DeleteItems';
  constructor(public items: Site[]) {}
}

export class DeleteItem {
  static readonly type = '[Site] DeleteItem';
  constructor(public item: Site) {}
}


