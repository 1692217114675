import { AuthStateModel } from './auth.statemodel';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import {
  Logout, ValidateTwoFactor, SignInMailPassword, SignInMailPasswordFail, ValidateDevice,
  LoadDeviceLocal, Forgot, ForgotConfirm, LoadSubItems, Flow, SignupPlan, LoadSignup, ResendFactor,
  LoadProfile, SendMail, SetLogin, InitAuthorize, SetGroup, ClearGroup
} from './auth.actions';
import { tap, catchError, take } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { TOKEN_TYPE, AuthDevice, Assinar, SubItem, SignupPlanClassesModel } from '../models/auth.interface';
import { of } from 'rxjs';
import { DeviceService } from '../../core/services/auth/device-detector.service';
import { NgxsEntityAdapter } from '../../store/ngxs-entityadapter';
import { SignUp, SignupPlanClasses, LoadSignupClasses, UpdateProfile, ClearError, SetAuthorize, SetFreemium } from './auth.actions';
import { SubItemService } from '../../core/services/auth/subitem.service';
import { UtilService } from '../../core/services/util.service';
import { PlanService } from '../../core/services/auth/plan.service';
import { SiteService } from '../../core/services/site/site.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { RdService } from 'src/app/core/services/tracking/rd.service';

@State<AuthStateModel>({
  name: 'enfcodesite',
  defaults: {
    email: null,
    token: null,
    credentialId: null,
    username: null,
    image: null,
    firstName: null,
    password: null,
    token_type: TOKEN_TYPE.TWO_FACTOR,
    deviceId: null,
    deviceFinded: null,
    error: null,
    success: null,
    loading: false,
    signUpPlanClasses: null,
    customer: null,
    subscription: null,
    dateAuthorize: null,
    classes: [],
    dependent: null,
    isFreemium: false,
    dateJoined: null,
    diasRestantes: null,
    isSuspended: null,
    isFreeSubscription: false,
    dateFreeSubscription: null
  }
})

@Injectable()
export class AuthState {

  constructor(
    private util: UtilService,
    private authService: AuthService,
    private deviceService: DeviceService,
    private subItemsService: SubItemService,
    private utilService: UtilService,
    private planService: PlanService,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private rdService: RdService
  ) { }

  @Selector()
  static auth(state: AuthStateModel): AuthStateModel {
    return state;
  }

  static profile(state: AuthStateModel): any {
    return state.profile;
  }

  static signup(state: AuthStateModel): any {
    return { username: state.username, password: state.password, deviceId: state.deviceId };
  }

  static assinar(state: AuthStateModel): Assinar {
    return state.assinar;
  }

  static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  static signin(state: AuthStateModel): any {
    return { username: state.username, password: state.password, deviceId: state.deviceId };
  }

  static twoFactor(state: AuthStateModel): any {
    return { code: '', username: state.username, deviceId: state.deviceId };
  }

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static deviceFinded(state: AuthStateModel): AuthDevice {
    return state.deviceFinded;
  }

  @Selector()
  static signUpPlanClasses(state: AuthStateModel): SignupPlanClassesModel {
    return state.signUpPlanClasses;
  }

  @Selector()
  static signUpPlans(state: AuthStateModel): SignupPlanClassesModel {
    return state.signUpPlans;
  }

  @Selector()
  static error(state: AuthStateModel): string | null {
    return state.error;
  }

  @Selector()
  static success(state: AuthStateModel): string | null {
    return state.success;
  }

  @Selector()
  static tokenType(state: AuthStateModel): string | null {
    return state.token_type;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return (state.token_type === TOKEN_TYPE.TOKEN) ? true : false;
  }


  @Action(Flow)
  async flow(ctx: StateContext<AuthStateModel>, action: Flow) {
    const result = action.result;
    if (result.type === TOKEN_TYPE.TOKEN) {
      localStorage.setItem('currentAutorization', result.token);

      this.route
        .queryParamMap
        .pipe(take(1)).toPromise().then((res: any) => {
          console.log('*** FLOW PARAMS', res.params.redirect);
          if (res.params.redirect) {
            ctx.dispatch(new Navigate(['dashboard/profile/subscriber-change']));
          } else {
            ctx.dispatch(new Navigate(['dashboard']));
          }
        });

    } else if (result.type === TOKEN_TYPE.DEVICE) {
      ctx.dispatch(new Navigate(['auth/device']));
    } else if (result.type === TOKEN_TYPE.TWO_FACTOR) {
      ctx.dispatch(new Navigate(['auth/two-factor']));
    } else if (result.type === TOKEN_TYPE.CREATE_FACTOR) {
      ctx.dispatch(new Navigate(['auth/create-factor']));
    }

  }

  @Action(SignInMailPassword)
  async signInMailPassword(ctx: StateContext<AuthStateModel>, action: SignInMailPassword) {

    ctx.patchState({ ...ctx.getState(), loading: true });

    const device = await this.deviceService.getDeviceInfo();
    action.payload.deviceOS = device.os;

    return this.authService.signinMailPassword(action.payload)
      .pipe(take(1)).toPromise()
      .then(resultAuth => {
        this.authService.validateSubscription(resultAuth.token, resultAuth.username)
          .then(result => {

            const { isFreeSubscription, dateJoined, dateFreeSubscription, subscription } = result;
            const isSuspended = this.util.isSuspendSubscription(isFreeSubscription, dateJoined, subscription, dateFreeSubscription);

            ctx.patchState({
              token: result.token, username: result.username,
              diasRestantes: this.util.getDiasRestantes(dateJoined),
              firstName: result.firstName,
              credentialId: result.credentialId,
              isSuspended,
              isFreeSubscription: isFreeSubscription,
              dateFreeSubscription: dateFreeSubscription,
              password: action.payload.password,
              token_type: result.type,
              dateJoined: dateJoined,
              customer: result.customer,
              subscription: subscription,
              error: null,
              deviceFinded: device,
              deviceId: null,
              dateAuthorize: null,
              loading: false
            });

            this.rdService.findContact(result.username).pipe(take(1)).toPromise().then(() => {
              this.rdService.updateContact({
                cf_assinatura: subscription?.planIdentifier,
                cf_assinado: !isSuspended
              }, result.username).pipe(take(1)).toPromise()
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }).catch((error: any) => {
              if (error?.status === 404) {
                this.rdService.createContact({
                  email: result.username,
                  name: result.firstName,
                  cf_profissao: 'Não Informado',
                  cf_assinatura: subscription?.planIdentifier,
                  cf_assinado: !isSuspended
                }).pipe(take(1)).toPromise().catch((err) => console.log(err));
              }
            });

            ctx.dispatch(new Flow(result));
          }).catch(err => {
            if (err.error.description) {
              ctx.patchState({ error: err.error, loading: false });
            } else {
              ctx.patchState({ error: { description: 'Não foi obter os acessos. Por favor, tente novamente!' }, loading: false });
            }
          }
          );
      })
      .catch(err => {
        if (err.error.description) {
          ctx.patchState({ error: err.error, loading: false });
        } else {
          ctx.patchState({ error: { description: 'Não foi possível realizar o login. Por favor, tente novamente!' }, loading: false });
        }
      });

  }


  @Action(SetLogin)
  async setLogin(ctx: StateContext<AuthStateModel>, action: SetLogin) {

    ctx.patchState({
      token: action.auth.token, username: action.auth.username, firstName: action.auth.firstName,
      credentialId: action.auth.credentialId, password: action.auth.password,
      dependent: action.auth.dependent, token_type: action.auth.type, error: null,
      isFreeSubscription: action.auth.isFreeSubscription, dateFreeSubscription: action.auth.dateFreeSubscription,
      subscription: action.auth.subscription, dateJoined: action.auth.dateJoined,
      deviceFinded: action.auth.device, customer: action.auth.customer, dateAuthorize: null,
      deviceId: null, loading: false
    });

    ctx.dispatch(new Flow(action.auth));

  }

  @Action(SetAuthorize)
  async setAuthorize(ctx: StateContext<AuthStateModel>, action: SetAuthorize) {

    const isSuspended = this.util.isSuspendSubscription(ctx.getState().isFreeSubscription,
      action.payload.dateJoined, action.payload.subscription, action.payload.dateFreeSubscription);
    console.log('***** SETAUTHORIZE ISSUSPENDED', isSuspended)
    ctx.patchState({
      ...ctx.getState(),
      isSuspended,
      classes: action.payload.classes,
      subscription: action.payload.subscription,
      dateAuthorize: new Date().toISOString()
    });

    ctx.dispatch([new SetFreemium()]);

  }

  @Action(SetFreemium)
  async setFreemium(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    const isSuspended = this.util.isSuspendSubscription(state.isFreeSubscription, state.dateJoined, state.subscription, state.dateFreeSubscription);

    ctx.patchState({ ...ctx.getState(), isSuspended });
  }

  @Action(SetGroup)
  async setGroup(ctx: StateContext<AuthStateModel>, action: SetGroup) {
    ctx.patchState({ ...ctx.getState(), groupSelect: action.group });
  }

  @Action(ClearGroup)
  async clearGroup(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ ...ctx.getState(), groupSelect: null });
  }

  @Action(InitAuthorize)
  async initAuthorize(ctx: StateContext<AuthStateModel>) {

    this.siteService.authorize(ctx.getState().credentialId).pipe(take(1)).toPromise()
      .then(result => {
        ctx.dispatch(new SetAuthorize(result));
        ctx.dispatch(new Navigate(['dashboard']));
      }).catch(err => {
        ctx.patchState({ ...ctx.getState(), error: err.message });
      });
  }

  @Action(SignInMailPasswordFail)
  signInMailPasswordFail(ctx: StateContext<AuthStateModel>, action: SignInMailPasswordFail) {
    ctx.setState({
      email: null, credentialId: null, customer: null, token: null, dependent: null,
      isFreeSubscription: null, dateFreeSubscription: null,
      username: null, token_type: null, error: action.payload.message, isSuspended: null,
      isFreemium: null, dateJoined: null, diasRestantes: null,
      password: null, dateAuthorize: null
    });
  }


  @Action(ValidateTwoFactor)
  validateTwoFactor(ctx: StateContext<AuthStateModel>, action: ValidateTwoFactor) {

    const state = ctx.getState();
    action.payload.deviceId = action.payload.deviceId;
    action.payload.username = state.username;

    return this.authService.signinTwoFactor({ username: state.username, code: action.payload.code, deviceId: state.deviceId }, state.token)
      .pipe(
        tap((result) => {

          ctx.patchState({
            token: result.token, username: action.payload.username,
            token_type: result.type, error: null, password: state.password
          });
          ctx.dispatch(new Navigate(['dashboard']));
        }),
        catchError(err => {
          ctx.patchState({
            token: state.token, username: state.username,
            token_type: state.token_type, error: err.description, password: state.password
          });
          return of(err);
        })
      );
  }


  @Action(Forgot)
  forgot(ctx: StateContext<AuthStateModel>, action: Forgot) {
    NgxsEntityAdapter.setItemSelected(ctx, action.payload);
  }

  @Action(ForgotConfirm)
  forgotConfirm(ctx: StateContext<AuthStateModel>, action: ForgotConfirm) {
    NgxsEntityAdapter.setLoading(ctx, true);

    return this.authService.forgotConfirm(action.token, action.password, action.passwordConfirm)
      .pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.setItemSelected(ctx, res);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new Navigate(['dashboard']));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });
  }

  @Action(ClearError)
  clearError(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setError(ctx, null);
  }

  @Action(SendMail)
  sendMail(ctx: StateContext<AuthStateModel>, action: SendMail) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setError(ctx, null);

    const state = ctx.getState();

    const payload = {
      id: 0,
      from: state.username,
      to: 'contato@medcode.com.br',
      subject: action.payload.subject,
      html: action.payload.html
    };

    this.authService.sendMail(payload)
      .pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.setItemSelected(ctx, res);
        NgxsEntityAdapter.setSuccess(ctx, 'Email enviado com sucesso!');
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
      });
  }

  @Action(LoadDeviceLocal)
  async loadDeviceLocal(ctx: StateContext<AuthStateModel>) {
    // this.setError(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);
    const state = ctx.getState();
    const device = await this.deviceService.getDeviceInfo();
    state.deviceId = device.key;
    // ACRESCENTA AS TAGS PARA O ONESIGNAL.
    if (state.subscription) {
      device.gateway = this.utilService.getGateway(state.subscription.gateway);
      device.subscription = 'ACTIVE';
    } else {
      device.gateway = 'NONE';
      device.subscription = 'DEACTIVE';
    }

    device.credentialId = state.credentialId;
    ctx.patchState({ ...state, deviceFinded: device });
  }

  @Action(LoadSubItems)
  loadItems(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    return this.subItemsService.listAll([])
      .pipe(
        tap((items: SubItem[]) => {
          NgxsEntityAdapter.setItems(ctx, items, items.length);
          NgxsEntityAdapter.setLoading(ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
        })
      );
  }

  @Action(ResendFactor)
  async resendFactor(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    this.authService.resendFactor(ctx.getState().username).pipe
      (take(1)).toPromise()
      .then(result => {
        NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });
  }


  @Action(SignUp)
  async signUp(ctx: StateContext<AuthStateModel>, action: SignUp) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    action.signup.credential.username = action.signup.credential.email;

    this.authService.signupUser(action.signup).pipe
      (take(1)).toPromise()
      .then(result => {
        ctx.patchState({

          token: result.token, username: result.username, firstName: result.firstName,
          token_type: result.type, error: null, loading: false
        });

        ctx.dispatch(new Navigate(['dashboard']));

        NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });

  }

  @Action(SignupPlanClasses)
  async signupPlanClasses(ctx: StateContext<AuthStateModel>, action: SignupPlanClasses) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);
    const credential = action.payload.credential;
    this.authService.subscribe(action.payload).pipe
      (take(1)).toPromise()
      .then(result => {
        ctx.dispatch(new Flow(result));
        NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });
  }

  @Action(SignupPlan)
  async signupPlan(ctx: StateContext<AuthStateModel>, action: SignupPlan) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);
    this.authService.subscribe(action.payload).pipe
      (take(1)).toPromise()
      .then(result => {
        ctx.dispatch(new Flow(result));
        NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });
  }


  @Action(LoadSignup)
  async loadSignup(ctx: StateContext<AuthStateModel>, action: LoadSignup) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    try {
      ctx.setState({
        ...ctx.getState(), signup: {
          id: 0, phoneNumber: '', roleId: 1,
          credential: {
            id: 0, email: '', firstName: '', lastLogin: '', isActive: true, lastName: '', password: '', username: ''
          }
        }
      });

      NgxsEntityAdapter.setLoading(ctx, false);
    } catch (err) {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
    }
  }

  @Action(LoadSignupClasses)
  async loadSignupClasses(ctx: StateContext<AuthStateModel>, action: LoadSignupClasses) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    try {
      const plan = await this.planService.findByIdentifier(action.identifier).pipe(take(1)).toPromise();
      ctx.setState({
        ...ctx.getState(), signUpPlanClasses: {
          plan: {
            id: plan.id, description: plan.description, identifier: plan.identifier, name: plan.name,
            price: plan.price, intervalType: plan.intervalType
          },
          subItems: [], credential: null, address: null, customer: null
        }
      });

      NgxsEntityAdapter.setLoading(ctx, false);
    } catch (err) {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
    }
  }

  @Action(ValidateDevice)
  validateDevice(ctx: StateContext<AuthStateModel>, action: ValidateDevice) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setError(ctx, null);

    const state = ctx.getState();
    const payload = action.device;
    state.deviceId = payload.key;

    return this.authService.deviceCreate(action.device).pipe(take(1)).toPromise()
      .then(res => {
        ctx.patchState(state);
        ctx.dispatch(new SignInMailPassword({
          username: state.username,
          password: state.password,
          deviceKey: state.deviceFinded.key,
          deviceOS: state.deviceFinded.os
        }));
      })
      .catch(err => {
        ctx.patchState({ error: err.error });
        NgxsEntityAdapter.setLoading(ctx, false);
      });
  }


  @Action(UpdateProfile)
  async updateProfile(ctx: StateContext<AuthStateModel>, action: UpdateProfile) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setError(ctx, null);
    NgxsEntityAdapter.setSuccess(ctx, null);

    this.siteService.updateProfile(action.profile).pipe
      (take(1)).toPromise()
      .then(result => {
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setSuccess(ctx, 'Perfil atualizado com sucesso!');
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
      });

  }

  private mountPayloadProfile(payload: any) {
    const pay = {
      id: payload.id,
      govId: payload.govId,
      gender: payload.gender,
      picture: payload.picture,
      phone: payload.phone,
      firstName: payload.credential.firstName,
      lastName: payload.credential.lastName,
      email: payload.credential.email,
      birthDate: payload.birthDate,
      credentialId: payload.credential.id,
      address: {
        id: payload.address.id,
        street: payload.address.street,
        addressNumber: payload.address.addressNumber,
        zipCode: payload.address.zipCode,
        neighborhood: payload.address.neighborhood,
        uf: payload.address.uf,
        city: payload.address.city,
        complement: payload.address.complement
      }
    };
    return pay;
  }

  @Action(LoadProfile)
  loadProfile(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);
    NgxsEntityAdapter.setLoading(ctx, true);

    const state = ctx.getState();
    return this.siteService.findByCredentialEmail(state.username).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.patchState({ ...state, profile: this.mountPayloadProfile(res) });
      })
      .catch(err => {
        state.error = err.error;
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.patchState({ ...state, error: err });
      });
  }

  setError(ctx: StateContext<AuthStateModel>, value: any) {
    const state = ctx.getState();
    state.error = value;
    ctx.patchState(state);
  }


  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.setState({
      credentialId: null, token: null,
      email: null, username: null,
      diasRestantes: null, isSuspended: null,
      isFreemium: null, dateJoined: null,
      token_type: null, error: null,
      isFreeSubscription: null,
      dateFreeSubscription: null,
      dateAuthorize: null, dependent: null,
      password: null, customer: null
    });
    ctx.dispatch(new Navigate(['/']));
  }
}
