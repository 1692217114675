import { NgModule } from '@angular/core';
import { UtilService } from './services/util.service';
import { BULAR_SERVICES } from './services/bulario/index';
import { CALC_SERVICES } from './services/calculators/index';
import { SITE_SERVICES } from './services/site/index';
import { CONTENTS_SERVICES } from './services/contents/index';
import { AUTH_SERVICES } from './services/auth/index';
import { GUARD_SERVICES } from './services/guards/indext';
import { FaqService } from 'src/app/core/services/faq/faq.service';
import { ANTIMICROBIAL_SERVICES } from './services/antimicrobial/index';

@NgModule({
  providers: [
    GUARD_SERVICES,
    UtilService,
    FaqService,
    BULAR_SERVICES,
    CALC_SERVICES,
    SITE_SERVICES,
    CONTENTS_SERVICES,
    AUTH_SERVICES,
    ANTIMICROBIAL_SERVICES
  ]
})
export class CoreModule { }
