import { Filter, Order } from 'src/app/formly/models/filters.model';
import { GroupClass } from '../models/groupclass.model';

export class LoadItems {
  static readonly type = '[GroupClass] LoadItems';
//   constructor(public limit: number, public offset: number, public filters: Filter[], public orders: Order[]) {}
}

export class FindItem {
  static readonly type = '[GroupClass] FindItem';
  constructor(public id: number) {}
}

export class AddEditItem {
  static readonly type = '[GroupClass] AddEditItem';
  constructor(public item: GroupClass) {}
}

export class EditItem {
  static readonly type = '[GroupClass] EditItem';
  constructor(public item: GroupClass) {}
}

export class SetLoading {
  static readonly type = '[GroupClass] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[GroupClass] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[GroupClass] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[GroupClass] SetSuccess';
  constructor(public msg: string) {}
}

export class SetItemActive {
  static readonly type = '[GroupClass] SetItemActive';
  constructor(public item: GroupClass) {}
}

export class SetItemsSelected {
  static readonly type = '[GroupClass] SetItemsSelected';
  constructor(public items: GroupClass[]) {}
}

export class DeleteItems {
  static readonly type = '[GroupClass] DeleteItems';
  constructor(public items: GroupClass[]) {}
}

export class DeleteItem {
  static readonly type = '[GroupClass] DeleteItem';
  constructor(public item: GroupClass) {}
}


