import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Site } from 'src/app/site/models/site.model';

@Injectable({
  providedIn: 'root'
})

export class SiteService extends GenerichttpService<Site> {

  constructor(protected injector: Injector) {
    super(Constants.SITE_CONTENT.toString(), injector, Site.fromJson);
  }

  listPosts(): Observable<any> {
    return this.http.get('assets/data/site_post.json').pipe(
      map(items => items),
      catchError(this.handleError)
    );
  }

  updateProfile(profile: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/updateprofile', profile)
    .pipe(
      catchError((err) => {
        console.log('error caught in service', err);
        return throwError(err);
      })
    );
  }

  updatePassword(payload: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/updatepassword', payload)
    .pipe(
      catchError((err) => {
        console.log('error caught in service', err);
        return throwError(err);
      })
    );
  }

  findByCredentialEmail(email: string): Observable<any> {
    return this.http.get(Constants.BASE_URL + 'user/findbycredentialemail/' + email).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

  authorize(id: number): Observable<any> {
    return this.http.get(Constants.BASE_URL + 'user/authorize/' + id).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }



}
