
export class SetBreadCrumb {
  static readonly type = '[BreadCrumb] SetBreadCrumb';
  constructor(public classe: any, public group: any, public subgroup: any, public element: any, public calculator: any) {}
}

export class FindBreadCrumb {
  static readonly type = '[BreadCrumb] FindBreadCrumb';

  constructor( public classeId: string, public groupId: string, public subgroupId: string, public elementId: string,
               public calculatorId: string, ) {}
}

export class SetLoading {
  static readonly type = '[BreadCrumb] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[BreadCrumb] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[BreadCrumb] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[BreadCrumb] SetSuccess';
  constructor(public msg: string) {}
}

