import { SubItem } from 'src/app/auth/models/auth.interface';
import { BaseResource } from 'src/app/formly/models/basersource.model';
import { GroupContent } from '../../groups/models/groupcontent.model';

export class GroupClass extends BaseResource {
  id: number;
  name: string;
  slug: string;
  color: string;
  order: number;
  // lastUpdate: string;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;
  type: string;
  groups?: GroupContent[];
  subItem: SubItem;

  static fromJson(jsonData: any): GroupClass {
    return Object.assign(new GroupClass(), jsonData);
  }
}

export const ATTRIBUTES_CLASS = ['id', 'name', 'slug', 'color', 'order', 'status', 'length'];


export const TABLE_GROUPCLASS = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'color', value: 'color', type: 'string'},
  { label: 'order', value: 'order', type: 'number'},
  { label: 'status', value: 'status', type: 'number'},
  { label: 'length', value: 'length', type: 'number'},
//   { label: 'lastUpdate', value: 'lastUpdate', type: 'date'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];

export const FIELD_STATUS = [
  { label: 'Excluído', value: 0},
  { label: 'Ativo', value: 1},
  { label: 'Aguardando publicação', value: 3},
];
