import { AuthDevice, SignupPlanClassesModel, SignUpModel } from '../models/auth.interface';

export class SignInMailPassword {
  static readonly type = '[Auth] SignInMailPassword';
  constructor(public payload: any) {}
}

export class SetFreemium {
  static readonly type = '[Auth] SetFreemium';
}

export class SetLogin {
  static readonly type = '[Auth] SetLogin';
  constructor(public auth: any) {}
}

export class SetGroup {
  static readonly type = '[Auth] SetGroup';
  constructor(public group: any) {}
}

export class ClearGroup {
  static readonly type = '[Auth] ClearGroup';
}

export class InitAuthorize {
  static readonly type = '[Auth] InitAuthorize';
}

export class SetAuthorize {
  static readonly type = '[Auth] SetAuthorize';
  constructor(public payload: any) {}
}

export class LoadSignup {
  static readonly type = '[Auth] LoadSignup';
}

export class LoadProfile {
  static readonly type = '[Auth] LoadProfile';
}

export class UpdateProfile {
  static readonly type = '[Auth] UpdateProfile';
  constructor(public profile: any) {}
}

export class SignUp {
  static readonly type = '[Auth] SignUp';
  constructor(public signup: SignUpModel) {}
}

export class ResendFactor {
  static readonly type = '[Auth] ResendFactor';
}

export class Assinar {
  static readonly type = '[Auth] Assinar';
  constructor(public assinar: Assinar) {}
}

export class ValidateDevice {
  static readonly type = '[Auth] ValidateDevice';
  constructor(public device: AuthDevice) {}
}

export class LoadDeviceLocal {
  static readonly type = '[Auth] LoadDeviceLocal';
}


export class LoadSubItems {
  static readonly type = '[Auth] LoadSubItems';
}

export class LoadSignupClasses {
  static readonly type = '[Auth] LoadSignupClasses';
  constructor(public identifier: string) {}
}

export class LoadSignupPlans {
  static readonly type = '[Auth] LoadSignupPlans';
  constructor(public identifier: string) {}
}

export class SignupPlanClasses {
  static readonly type = '[Auth] SignupPlanClasses';
  constructor(public payload: SignupPlanClassesModel) {}
}

export class SignupPlan {
  static readonly type = '[Auth] SignupPlan';
  constructor(public payload: SignupPlanClassesModel) {}
}

export class ClearError {
  static readonly type = '[Auth] ClearError';
}

export class DeviceFind {
  static readonly type = '[Auth] DeviceFind';
}

export class DeviceCreate {
  static readonly type = '[Auth] DeviceCreate';
  constructor(public device: AuthDevice) {}
}

export class Flow {
  static readonly type = '[Auth] Flow';
  constructor(public result: any) {}
}

export class SendMail {
  static readonly type = '[Auth] SendMail';
  constructor(public payload: any) {}
}

export class ValidateTwoFactor {
  static readonly type = '[Auth] ValidateTwoFactor';
  constructor(public payload: { username: string; code: string, deviceId: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SignInMailPasswordFail {
  static readonly type = '[Auth] SignInMailPasswordFail';
  constructor(public payload: { code: string; message: string, description: string }) {}
}

export class Forgot {
  static readonly type = '[Auth] Forgot';
  constructor(public payload: any) {}
}

export class ForgotConfirm {
  static readonly type = '[Auth] ForgotConfirm';
  constructor(public token: string, public password: string, public passwordConfirm: string) {}
}
