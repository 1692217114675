import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './site.actions';

import { Navigate } from '@ngxs/router-plugin';
import { Site } from '../models/site.model';
import { SiteService } from 'src/app/core/services/site/site.service';
import { MigrarPosts } from './site.actions';

export class SiteStateModel extends NgxsEntityStateModel<Site> {}

@State<SiteStateModel>({
  name: 'Site',
  defaults: SiteStateModel.InitialState()
})

@Injectable()
export class SiteState {

  constructor(private siteService: SiteService) { }

  @Selector()
  static entities(state: SiteStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: SiteStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: SiteStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: SiteStateModel) {
    return state;
  }

  @Selector()
  static items(state: SiteStateModel) {
    console.log('SITE ITEMS', state.items);
    return state.items;
  }

  @Selector()
  static banner(state: SiteStateModel) {
    console.log('Banner', state.items);
    return state.items.find(item => item.section ===  'banner');
  }

  @Selector()
  static services(state: SiteStateModel) {
    console.log('Services', state.items);
    return state.items.filter(item => item.section ===  'services');
  }

  @Selector()
  static team(state: SiteStateModel) {
    console.log('Services', state.items);
    return state.items.filter(item => item.section ===  'team');
  }

  @Selector()
  static partners(state: SiteStateModel) {
    console.log('Partners', state.items);
    return state.items.filter(item => item.section ===  'partners');
  }

  @Selector()
  static plans(state: SiteStateModel) {
    console.log('Planos de Assinatura', state.items);
    return state.items.filter(item => item.section ===  'plans');
  }


  @Selector()
  static count(state: SiteStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: SiteStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: SiteStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: SiteStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: SiteStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: SiteStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  findItem(ctx: StateContext<SiteStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    if (action.id === 0) {
      const item: any = { id: 0 };
      NgxsEntityAdapter.setItemSelected(ctx, item);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(item);
    } else {
      this.siteService.getById(action.id).pipe(take(1)).toPromise()
      .then(item => {
          console.log('ITEM ', item);
          NgxsEntityAdapter.setItemSelected(ctx, item);
          NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }
}


  @Action(LoadItems)
  loadItems(ctx: StateContext<SiteStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    return this.siteService.filters(action.limit, action.offset, action.filters,
      action.orders, action.inclues, action.filtersFormated)
    .pipe(
        tap((res: any) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res.rows, res.count);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(MigrarPosts)
  async migrarPosts(ctx: StateContext<SiteStateModel>) {
    NgxsEntityAdapter.setLoading(ctx, true);
    const posts: any[] = await this.siteService.listPosts().pipe(take(1)).toPromise();
    console.log('***** POSTS *****', posts);

    try {
        for (const item of posts) {
          this.siteService.create(item).pipe(take(1)).toPromise();
        }
        NgxsEntityAdapter.setLoading(ctx, false);
    } catch (err) {
      console.log('ERRR', err);
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }
   }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<SiteStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    if (action.item.id === 0) {
      return this.siteService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/site/content/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.siteService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/site/content/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }

   }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<SiteStateModel>, action: DeleteItem) {
     /* return this.siteService.deletePermWithRoles(siteService.item)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res.decription);
       ctx.dispatch(new Navigate(['dashboard/elements/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     }); */
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<SiteStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.siteService.deleteMany(siteService.items.m.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/elements/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<SiteStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<SiteStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
