import { AuthFacade } from 'src/app/auth/facade/auth.facade';
import { AuthState } from 'src/app/auth/store/auth.state';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { GroupClass } from '../../../dashboard/classes/models/groupclass.model';
import { GroupContent } from 'src/app/dashboard/groups/models/groupcontent.model';
import { GroupContentService } from '../contents/groupcontent.service';
import { tap, take } from 'rxjs/operators';
import { AuthStateModel } from '../../../auth/store/auth.statemodel';
import { StatusSubscription } from '../../constantes/common.constante';
import { InitAuthorize } from 'src/app/auth/store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class SubscriberGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
    private facade: AuthFacade,
    private groupService: GroupContentService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const auth: AuthStateModel = this.store.selectSnapshot(AuthState.auth);
      console.log('*** SUBSCRIBE_GUARD', auth);

      if (auth.isSuspended) {
        this.facade.clearGroup();
        this.router.navigateByUrl('dashboard/profile/subscriber');
        return false;
      }
      this.facade.clearGroup();
      return true;
    }

}
