import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './groupcontent.actions';
import { Navigate } from '@ngxs/router-plugin';
import { GroupContent, ATTRIBUTES_GROUPCONTENT } from '../models/groupcontent.model';
import { GroupContentService } from 'src/app/core/services/contents/groupcontent.service';

export class GroupContentStateModel extends NgxsEntityStateModel<GroupContent> {}

@State<GroupContentStateModel>({
  name: 'GroupContent',
  defaults: GroupContentStateModel.InitialState()
})

@Injectable()
export class GroupContentState {

  constructor(private gcService: GroupContentService) { }

  @Selector()
  static entities(state: GroupContentStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: GroupContentStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: GroupContentStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: GroupContentStateModel) {
    return state;
  }

  @Selector()
  static items(state: GroupContentStateModel) {
    return state.items;
    // return Object.keys(state.entities).map(id => state.entities[id]);
  }

  @Selector()
  static count(state: GroupContentStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: GroupContentStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: GroupContentStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: GroupContentStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: GroupContentStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: GroupContentStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  async findItem(ctx: StateContext<GroupContentStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    if (action.id === '0') {
      const item: any = { id: '0'};
      NgxsEntityAdapter.setItemSelected(ctx, item);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(item);
    } else {
      const group = await this.gcService.getBySlug(action.id).pipe(take(1)).toPromise();
      console.log('GROUP SELECIONDO', group);
      NgxsEntityAdapter.setItemSelected(ctx, group);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(group);
    }
}


  @Action(LoadItems)
  loadItems(ctx: StateContext<GroupContentStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    return this.gcService.filters(action.limit, action.offset, action.filters, action.orders)
    .pipe(
        tap((res: any) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res.rows, res.count);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<GroupContentStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);

    if (action.item.id === 0) {
      return this.gcService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setError(ctx, null);
        ctx.dispatch(new NavigateToPage('dashboard/groups/lista'));

      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.gcService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setError(ctx, null);
        ctx.dispatch(new NavigateToPage('dashboard/groups/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }
   }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<GroupContentStateModel>, action: DeleteItem) {
     return this.gcService.delete(action.item.id).pipe(take(1)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res);
       ctx.dispatch(new Navigate(['dashboard/groups/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     });
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<GroupContentStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.gcService.deleteMany(action.items.map(i => i.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/GroupContents/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<GroupContentStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<GroupContentStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
