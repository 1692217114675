import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { AntiMicrobialInfeccoes } from 'src/app/dashboard/microbial/models/microbial.model';

@Injectable({
  providedIn: 'root'
})

export class AntimicrobialInfeccoesService extends GenerichttpService<AntiMicrobialInfeccoes> {

  constructor(protected injector: Injector) {
    super(Constants.ANTIMICROBIAL_INFECCOES.toString(), injector, AntiMicrobialInfeccoes.fromJson);
  }

}
