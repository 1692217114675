import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { BularClass } from 'src/app/dashboard/bulario/models/bular.model';

@Injectable({
  providedIn: 'root'
})

export class BularClassService extends GenerichttpService<BularClass> {

  constructor(protected injector: Injector) {
    super(Constants.BULAR_CLASSES.toString(), injector, BularClass.fromJson);
  }

}
