import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { SubItem } from 'src/app/auth/models/auth.interface';

@Injectable({
  providedIn: 'root'
})

export class SubItemService extends GenerichttpService<SubItem> {

  constructor(protected injector: Injector) {
    super(Constants.SUBITEM.toString(), injector, SubItem.fromJson);
  }

}
