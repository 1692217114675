import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BularBula } from 'src/app/dashboard/bulario/models/bular.model';

@Injectable({
  providedIn: 'root'
})

export class BularBulasService extends GenerichttpService<BularBula> {

  constructor(protected injector: Injector) {
    super(Constants.BULAR_BULAS.toString(), injector, BularBula.fromJson);
  }

  findWithAssociations(id: number): Observable<BularBula[]> {
    console.log('ENTROU EM FIND ASSOCIATIONS', id);
    return this.http.get(`${Constants.endPoint(this.apiPath, '/findWithAssociations')}/${id}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

}
