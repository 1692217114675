import { Filter, Order } from 'src/app/formly/models/filters.model';
import { Include } from '../../../formly/models/filters.model';
import { CalcItem } from '../models/calc-items.model';

export class LoadItems {
  static readonly type = '[CalcItem] LoadItems';
  constructor(
    public limit: number, public offset: number,
    public filters: Filter[], public orders: Order[],
    public inclues?: Include[], public filtersFormated?: Filter[]) {}
}

export class FindItem {
  static readonly type = '[CalcItem] FindItem';
  constructor(public id: number) {}
}

export class AddEditItem {
  static readonly type = '[CalcItem] AddEditItem';
  constructor(public item: CalcItem) {}
}

export class EditItem {
  static readonly type = '[CalcItem] EditItem';
  constructor(public item: CalcItem) {}
}

export class SetLoading {
  static readonly type = '[CalcItem] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[CalcItem] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[CalcItem] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[CalcItem] SetSuccess';
  constructor(public msg: string) {}
}

export class SetItemActive {
  static readonly type = '[CalcItem] SetItemActive';
  constructor(public item: CalcItem) {}
}

export class SetItemsSelected {
  static readonly type = '[CalcItem] SetItemsSelected';
  constructor(public items: CalcItem[]) {}
}

export class DeleteItems {
  static readonly type = '[CalcItem] DeleteItems';
  constructor(public items: CalcItem[]) {}
}

export class DeleteItem {
  static readonly type = '[CalcItem] DeleteItem';
  constructor(public item: CalcItem) {}
}


