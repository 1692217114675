import { environment } from 'src/environments/environment.prod';

export const LOGO_WHITE = 'https://firebasestorage.googleapis.com/v0/b/imappiempresas.appspot.com/o/imappi%2Fimappi.png?alt=media&token=2e00aad6-d1d4-4dad-a06c-27a5c20cec1f';
export const IMG_USER_DEFAULT = 'https://firebasestorage.googleapis.com/v0/b/imappiempresas.appspot.com/o/imappi%2Fuser_default.png?alt=media&token=51a28260-61a4-4b4e-b136-7deb5d77f478';
export const LOGO_EMPRESA_DEFAULT = 'https://firebasestorage.googleapis.com/v0/b/imappiempresas.appspot.com/o/imappi%2Flogoempresa.png?alt=media&token=6f0e0aa8-2ff0-40c0-a8ac-6f83b9f9afc8';

export const CAPA = 'https://firebasestorage.googleapis.com/v0/b/imappiempresas.appspot.com/o/imappi%2Fnyc.jpg?alt=media&token=f28f6d02-5198-4818-be63-7a448a355dc7';
export const LOGO = 'https://firebasestorage.googleapis.com/v0/b/imappiempresas.appspot.com/o/imappi%2Flogo_degrade.png?alt=media&token=bfa7f5e8-dfda-416f-86b9-fe93233d7aed';

//  export const URL_API = 'http://192.168.15.12:3000';
// export const URL_API = 'http://127.0.0.1:3000';
// export const URL_API = 'https://api.medcode.com.br';

// executar quando a conexao for pelo celular... olhar sempre o IP do DevApp do celular
// export const URL_API = 'http://172.20.10.2:3000';


export enum  StatusSubscription {
  ATIVO = 2,
  INATIVO = 4,
  SUSPENSO = 5,

}

export class Constants {
  private static V1 = '/v1/';
  private static idioma = '/pt';
  public static BASE_URL = environment.host;

  static LIST_DATA_TABLE = '/listDataTable';
  static LIST_OPERATOR_TYPE = '/operatorTypes';
  static LIST       = '/list';
  static FILTERS       = '/filters';
  static LISTALL       = '/listAll';
  static LIST_INCLUDES       = '/listIncludes';
  static FIND_BY_ID = '/find';
  static FIND_BY_SLUG = '/findslug';

  static FIND_BY_ID_INCLUDES = '/findWithIncludes';
  static FIND_BY_FILE_NAME = '/findByName';
  static FIND_BY_CPF = '/find/cpf';
  static REPORT_BY_ID_YEAR_MONTH = '/report';
  static CREATE     = '/create';
  static UPDATE     = '/update';
  static DELETE     = '/delete';
  static DELETE_MANY     = '/deleteMany';
  static TOKEN      = '/token';
  static SIMPLE_LOGIN      = '/simpleLogin';
  static VALIDATE_SUBSCRIPTION      = '/validateSubscription';
  static FORGOT      = '/forgot';
  static REWRITE      = '/rewrite';

  static PERMISSION = 'permission';
  static ELEMENT = 'element';
  static GROUPCLASS = 'groupclass';
  static GROUPCONTENT = 'groupcontent';
  static ICON = 'icon';

  static AUTHDEVICE = 'device';
  static DEVICEFAMILY = 'devicefamily';


  static ANTIMICROBIAL_CATEGORY = 'microbial/cat';
  static ANTIMICROBIAL_CONFIG = 'microbial/config';
  static ANTIMICROBIAL_EXTRAS = 'microbial/extra';
  static ANTIMICROBIAL_INFECCOES = 'microbial/infeccao';
  static ANTIMICROBIAL_PROFILAXIA = 'microbial/profilaxia';
  static ANTIMICROBIAL_RENALADJUSTGROUP = 'microbial/renaladjustgroup';
  static ANTIMICROBIAL_RENALADJUST = 'microbial/renaladjust';

  static CID = 'cid';

  static EPONIMO = 'dadostabulados/two';

  static PROCEDIMENTOS = 'dadostabulados/two';

  static DRUG_PRICE = 'dadostabulados/price';

  static CALCULATORS_ITEMS = 'calculadora';
  static CALCULATORS_CONFIG = 'calculadora/config';

  static MENTOSA = 'mentosa';
  static MENTOSAS_CONFIG = 'mentosa/config';

  static TABS_PRICES = 'dadostabulados/price';
  static TABS_CONFIG = 'dadostabulados/config';
  static TABS_TWO_COLUMNS = 'dadostabulados/two';

  static BULAR_BULAS = 'bulario/bula';
  static BULAR_CONFIG = 'bulario/config';
  static BULAR_LABS = 'bulario/lab';
  static BULAR_CLASSES = 'bulario/class';
  static BULAR_SUBLCASSES = 'bulario/subclass';
  static BULAR_ESPEC = 'bulario/espec';

  static RESIDENCE_ANSWER = 'residence/answer';
  static RESIDENCE_CONFIG = 'residence/config';
  static RESIDENCE_INSTITUTE = 'residence/institute';
  static RESIDENCE_QUESTION = 'residence/question';
  static RESIDENCE_TEST = 'residence/test';

  static SITE = 'site';
  static SITE_CATEGORY = 'site/category';
  static SITE_COMMENT = 'site/comment';
  static SITE_PAGE = 'site/page';
  static SITE_POST = 'site/post';
  static SITE_CONTENT = 'site/content';
  static SITE_PARTNER = 'site/partner';
  static SITE_USER = 'site/user';


  static FAQ = 'faq';
  static ROLE = 'role';
  static AUTH = 'security';

  static POSITION   = 'position';
  static CREDENTIAL        = 'credential';
  static SECURITY   = 'security';
  static USER        = 'user';
  static PLAN        = 'plan';
  static ADDRESS        = 'address';
  static CUSTOMER        = 'customer';
  static NOTIFICATION        = 'notification';
  static IUGU        = 'iugu';
  static GOOGLE        = 'google';
  static APPLE        = 'apple';

  static ADDRESS_COUNTRIES        = Constants.endPoint('address', '/listCountries');;

  static SUBITEM        = 'site/user/subitems';
  static DEVICE        = 'device';
  static MAIL_POST        = Constants.endPoint('mail', '/post');

  static LOGIN      =  Constants.endPoint(Constants.USER, Constants.SIMPLE_LOGIN);
  static FORGOTINIT     =  Constants.endPoint(Constants.SECURITY, Constants.FORGOT);
  static FORGOTCONFIRM     =  Constants.endPoint(Constants.SECURITY, Constants.REWRITE);

  static SITE_FILTERS      =  Constants.endPoint(Constants.SITE_CONTENT, Constants.FILTERS);
  static SITE_PLANS      =  Constants.endPoint(Constants.PLAN, Constants.LIST);
  static SITE_PLANS_ALL      =  Constants.endPoint(Constants.PLAN, '/listAll');
  static SITE_POST_LIST      =  Constants.endPoint(Constants.SITE_POST, Constants.LIST);
  static SITE_POST_FILTERS      =  Constants.endPoint(Constants.SITE_POST, Constants.FILTERS);
  static SITE_POST_FINDINCLUDES      =  Constants.endPoint(Constants.SITE_POST, Constants.FIND_BY_ID_INCLUDES);
  static GROUPCLASS_LIST  =  Constants.endPoint(Constants.GROUPCLASS, Constants.LIST);

  static PLAN_FIND_IDENTIFIER = Constants.endPoint(Constants.PLAN, '/findByIdentifier');

  static FAQ_LIST = Constants.endPoint(Constants.FAQ, '/list');

  static SIGNUP      =  Constants.endPoint(Constants.USER, '/simpleSignUp');
  static SIGNUP_PLAN_CLASSES      =  Constants.endPoint(Constants.USER, '/subItems');
  static SIGNUP_PLAN_INDIVIDUAL      =  Constants.endPoint(Constants.USER, '/signupPlanIndividual');
  static FACTOR_RESEND      =  Constants.endPoint(Constants.USER, '/resendfactor');
  static SUBSCRIBE      =  Constants.endPoint(Constants.USER, '/subscribe');
  static SUB_ITEMS      =  Constants.endPoint(Constants.USER, '/subItems');
  static SITE_AUTHORIZE      =  Constants.endPoint(Constants.USER, '/authorize');
  static DEVICE_CREATE      =  Constants.endPoint(Constants.DEVICE, '/create');


  /****** CONSTANTS ERRORS  */
  static ERROR_MESSAGES = [
    { code: 'REGISTRY_SUCCESS_CREATED', message: 'REGISTRY_SUCCESS_CREATED'},
    { code: 'DEVICE_QTD_NOT_AUTHORIZED', message: 'Você já atingiu a cota de dispositivos permitidos pelo seu plano!'},
    { code: 'DEVICE_TYPE_NOT_AUTHORIZED', message: 'Seu plano não permite esse tipo de dispositivo!'},
    { code: 'UNIQUE_CONSTRAINT_SUBSCRIPTION', message: 'Você já possui uma assinatura!'},
    { code: 'UNIQUE_CONSTRAINT_SECONDFACTOR', message: 'Já existe um dispositivo com esse número!'},
    { code: 'UNIQUE_CONSTRAINT_CREDENTIAL', message: 'Você já possui uma conta com essas credenciais!'},
    { code: 'NOT_FOUND_REGISTER', message: 'Registro não encontrado!'},
    { code: 'PASSWORD_NOT_MATCH_ERROR', message: 'Senha errada!'},
    { code: 'LIST_ERROR_DATA', message: 'Não foi possível carregar os dados!'},
    { code: 'CUSTOMER_NOT_FOUND', message: 'Cliente não encontrado!'},
    { code: 'INVALID_PLAN_IDENTIFIER', message: 'Plano não identificado!'},
    { code: 'INVALID_TWO_FACTOR_CODE', message: 'Código de validação de dois fatores inválido!'},
    { code: 'INVALID_ACCOUNT_INFORMATION', message: 'Credenciais inválidas!'},
    { code: 'INVALID_LOGIN_OR_PASSWORD', message: 'Login ou senha inválidos!'}
  ];

/*   static REGISTRY_SUCCESS_UPDATED = 'REGISTRY_SUCCESS_UPDATED';
  static REGISTRY_SUCCESS_DELETE = 'REGISTRY_SUCCESS_DELETE';
  static INVALID_LOGIN_OR_PASSWORD = 'Login ou senha inválidos';
  static INVALID_TWO_FACTOR_CODE = 'Código de validação de dois fatores inválido!';
  static INVALID_ACCOUNT_INFORMATION = 'Credenciais inválidas!';
  static INVALID_PLAN_IDENTIFIER = 'Plano não identificado';
  static CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND';
  static INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
  static CREATE_ERROR_DATA = 'CREATE_ERROR_DATA';
  static CREATE_ERROR_IUGU_DATA = 'CREATE_ERROR_IUGU_DATA';
  static UPDATE_ERROR_DATA = 'UPDATE_ERROR_DATA';
  static UPDATE_ERROR_IUGU_DATA = 'UPDATE_ERROR_IUGU_DATA';
  static LIST_ERROR_DATA = 'LIST_ERROR_DATA';
  static FIND_ERROR_DATA = 'FIND_ERROR_DATA';
  static DELETE_ERROR_DATA = 'FIND_ERROR_DATA';
  static DELETE_ERROR_IUGU_DATA = 'DELETE_ERROR_IUGU_DATA'; */

  static endPoint(modulePath: string, apiPath: string) {
    return Constants.BASE_URL + modulePath + apiPath;
  }

  static endPointDataTable(modulePath: string, apiPath: string, token: string) {
    return Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '?token=' + token;
  }

  static endPointDataTableById(modulePath: string, apiPath: string, id: number, token: string) {
    console.log(Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '/' + id + '?token=' + token);
    return Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '/' + id + '?token=' + token;
  }

  static endPointEdit(modulePath: string, id: number) {
    return `/${modulePath}/${id}/edit`;
  }




}
