import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ElementService } from 'src/app/core/services/contents/element.service';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './icon.actions';

import { Navigate } from '@ngxs/router-plugin';
import { Icon } from '../models/icon.model';
import { IconService } from 'src/app/core/services/contents/icon.service';

export class IconStateModel extends NgxsEntityStateModel<Icon> {}

@State<IconStateModel>({
  name: 'icon',
  defaults: IconStateModel.InitialState()
})

@Injectable()
export class IconState {

  constructor(private iconService: IconService, private elService: ElementService) { }

  @Selector()
  static entities(state: IconStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: IconStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: IconStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: IconStateModel) {
    return state;
  }

  @Selector()
  static items(state: IconStateModel) {
    return state.items;
    // return Object.keys(state.entities).map(id => state.entities[id]);
  }

  @Selector()
  static count(state: IconStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: IconStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: IconStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: IconStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: IconStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: IconStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  async findItem(ctx: StateContext<IconStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    if (action.id === '0') {
          const item: any = { id: '0' };
          NgxsEntityAdapter.setItemSelected(ctx, item);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(item);
    } else {

      this.iconService.getBySlug(action.id).pipe(take(1)).toPromise()
      .then(icon => {
          console.log('ICON ', icon);
          NgxsEntityAdapter.setItemSelected(ctx, icon);
          NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }
}


  @Action(LoadItems)
  loadItems(ctx: StateContext<IconStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    const includes = [{ attributes: ['id', 'name'], model: 'Element', as: 'element' }];
    return this.iconService.filters(action.limit, action.offset, action.filters, action.orders, includes)
    .pipe(
        tap((res: any) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res.rows, res.count);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<IconStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    if (action.item.id === 0) {
      return this.iconService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setError(ctx, null);
        ctx.dispatch(new NavigateToPage('dashboard/icons/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.iconService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setError(ctx, null);
        ctx.dispatch(new NavigateToPage('dashboard/icons/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
   }
  }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<IconStateModel>, action: DeleteItem) {
     return this.iconService.delete(action.item.id).pipe(take(1)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res);
       ctx.dispatch(new Navigate(['dashboard/icons/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     });
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<IconStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.iconService.deleteMany(action.items.map(i => i.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/elements/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<IconStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<IconStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
