import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import { BreadCrumb } from '../../../models/breadcrumb.model';
import { SetBreadCrumb, FindBreadCrumb } from './breadcrumb.actions';
import { GroupContentService } from '../../../../core/services/contents/groupcontent.service';
import { ElementService } from '../../../../core/services/contents/element.service';
import { CalcItemService } from '../../../../core/services/calculators/calc-items.service';

export class BreadCrumbStateModel extends NgxsEntityStateModel<BreadCrumb> {}

@State<BreadCrumbStateModel>({
  name: 'BreadCrumb',
  defaults: BreadCrumbStateModel.InitialState()
})

@Injectable()
export class BreadCrumbState {

  constructor(
    private groupService: GroupContentService,
    private elementService: ElementService,
    private calcService: CalcItemService
  ) { }


  @Selector()
  static itemSelected(state: BreadCrumbStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static success(state: BreadCrumbStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: BreadCrumbStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: BreadCrumbStateModel) {
    return state.loading;
  }


  @Action(SetBreadCrumb)
  setBreadCrumb(ctx: StateContext<BreadCrumbStateModel>, action: SetBreadCrumb) {
    const state = (ctx.getState().itemSelected) ? (ctx.getState().itemSelected.classe) : { id: 0 , name: 'Classes'};
    const classe = (action.classe) ? { id: action.classe.id, name: action.classe.name } : state;

    const group = (action.group) ?
    { id: action.group.id, name: action.group.name, iconBase64: action.group.iconBase64,
      color: action.group.color, groupParent: action.group.groupParent } : null;
    const subgroup = (action.subgroup) ? { id: action.subgroup.id, name: action.subgroup.name } : null;

    const element = (action.element) ? { id: action.element.id, name: action.element.name } : null;
    const calculator = (action.calculator) ? { id: action.calculator.id, name: action.calculator.name } : null;

    const bread = { classe, group, subgroup, element, calculator };
    NgxsEntityAdapter.setItemSelected(ctx, bread );
  }

  @Action(FindBreadCrumb)
  async findBreadCrumb(ctx: StateContext<BreadCrumbStateModel>, action: FindBreadCrumb) {
    NgxsEntityAdapter.setLoading(ctx, true);

    const group = (action.groupId) ?
    await this.groupService.getBySlug(action.groupId)
    .pipe(take(1)).toPromise() : null;

    console.log('GROUP PARENT', group.groupParent);

    const subgroup = (action.subgroupId) ?
      await this.groupService.getBySlug(action.subgroupId).pipe(take(1)).toPromise() : null;

    // const element = (action.elementId) ?
    // await this.elementService.findByIdWithIncludes(action.elementId, ['id', 'name']).pipe(take(1)).toPromise() : null;

    // const calculator = (action.calculatorId) ?
    // await this.calcService.findByIdWithIncludes(action.calculatorId, ['id', 'name']).pipe(take(1)).toPromise() : null;

    ctx.dispatch([new SetBreadCrumb(null, group, subgroup, null, null)]);
    // ctx.dispatch([new SetBreadCrumb(null, group, subgroup, element, calculator)]);
    NgxsEntityAdapter.setLoading(ctx, false);
  }

}
