import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Constants } from '../../constantes/common.constante';
import { map, catchError } from 'rxjs/operators';
import { GenerichttpService } from '../generic/generichttp.service';
import { Element } from './../../../dashboard/elements/models/element.model';

@Injectable({
  providedIn: 'root'
})

export class ElementService extends GenerichttpService<Element> {

  constructor(protected injector: Injector) {
    super(Constants.ELEMENT.toString(), injector, Element.fromJson);
  }

  findWithAssociations(id: number): Observable<Element[]> {
    console.log('ENTROU EM FIND ASSOCIATIONS', id);
    return this.http.get(`${Constants.endPoint(this.apiPath, '/findWithAssociations')}/${id}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

  filterSearch(term: string): Observable<any> {
    return this.http.get(`${Constants.endPoint(this.apiPath, '/filterSearch')}/${term}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

}
