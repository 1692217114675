import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { CalcItem } from '../../../dashboard/calculators/models/calc-items.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FaqItem } from '../../../dashboard/faq/faq-items/models/faq.model';

@Injectable({
  providedIn: 'root'
})

export class FaqService extends GenerichttpService<FaqItem> {

  constructor(protected injector: Injector) {
    super(Constants.FAQ.toString(), injector, FaqItem.fromJson);
  }

}
