import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { GroupContent } from 'src/app/dashboard/groups/models/groupcontent.model';

@Injectable({
  providedIn: 'root'
})

export class GroupContentService extends GenerichttpService<GroupContent> {

  constructor(protected injector: Injector) {
    super(Constants.GROUPCONTENT.toString(), injector, GroupContent.fromJson);
  }

}
