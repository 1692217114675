import { Filter, Order } from 'src/app/formly/models/filters.model';
import { Include } from '../../../../formly/models/filters.model';
import { FaqItem } from '../models/faq.model';

export class LoadItems {
  static readonly type = '[FaqItem] LoadItems';
}

export class FindItem {
  static readonly type = '[FaqItem] FindItem';
  constructor(public id: string) {}
}

export class AddEditItem {
  static readonly type = '[FaqItem] AddEditItem';
  constructor(public item: FaqItem) {}
}

export class EditItem {
  static readonly type = '[FaqItem] EditItem';
  constructor(public item: FaqItem) {}
}

export class SetLoading {
  static readonly type = '[FaqItem] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[FaqItem] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[FaqItem] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[FaqItem] SetSuccess';
  constructor(public msg: string) {}
}

export class SetItemActive {
  static readonly type = '[FaqItem] SetItemActive';
  constructor(public item: FaqItem) {}
}

export class SetItemsSelected {
  static readonly type = '[FaqItem] SetItemsSelected';
  constructor(public items: FaqItem[]) {}
}

export class DeleteItems {
  static readonly type = '[FaqItem] DeleteItems';
  constructor(public items: FaqItem[]) {}
}

export class DeleteItem {
  static readonly type = '[FaqItem] DeleteItem';
  constructor(public item: FaqItem) {}
}


