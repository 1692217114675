import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResource } from 'src/app/formly/models/basersource.model';
import { Constants } from '../../constantes/common.constante';
import { Order, Filter } from 'src/app/formly/models/filters.model';
import { Include } from '../../../formly/models/filters.model';
import * as moment from 'moment';

export abstract class GenerichttpService<T extends BaseResource> {
  protected http: HttpClient;

  constructor(
    protected apiPath: string,
    protected injector: Injector,
    protected jsonDataToResourceFn: (jsonData: any) => T
  )
  {
    this.http = injector.get(HttpClient);
  }

  list(): Observable<T[]> {
    // console.log('*** PATH ***', Constants.endPoint(this.apiPath, Constants.LIST));
    return this.http.get<T[]>(Constants.endPoint(this.apiPath, Constants.LIST)).pipe(
      map(this.jsonDataToResources.bind(this)),
      catchError(this.handleError)
    );
  }

  listToSite(): Observable<T[]> {
    // console.log('*** PATH ***', Constants.endPoint(this.apiPath, Constants.LIST));
    return this.http.get<T[]>(Constants.endPoint(this.apiPath, '/listToSite')).pipe(
      map(this.jsonDataToResources.bind(this)),
      catchError(this.handleError)
    );
  }

  listAll(attributes: string[]): Observable<T[]> {
    return this.http.post<T[]>(Constants.endPoint(this.apiPath, Constants.LISTALL), attributes).pipe(
      map(items => items),
      catchError(this.handleError)
    );
  }

  listIncludes(attributes: string[], includes: any[]): Observable<T[]> {
    const payload = (attributes) ? { attributes, includes } : { includes };
    console.log('LIST ALL PAYLOAD', payload);
    return this.http.post<T[]>(Constants.endPoint(this.apiPath, Constants.LIST_INCLUDES), payload).pipe(
      map(items => items),
      catchError(this.handleError)
    );
  }

  filters(
    limit: number, offset: number, modelFilters: any[], orders: Order[],
    includes?: any[], filtersFormatted?: Filter[], attributes?: string[]): Observable<T[]> {

    /* console.log('*** FILTERS FORMATTED ***', JSON.stringify(filtersFormatted));
    console.log('*** MODEL FILTERS  ***', JSON.stringify(Filter.transFormToIFilters(modelFilters))); */

    const filters: Filter[] = (filtersFormatted) ? filtersFormatted : Filter.transFormToIFilters(modelFilters);

    includes = (includes) ? includes : [];

    const payload = {attributes, limit, offset, filters, includes, orders};

    // console.log('*** PAYLOAD FILTERS  ***', JSON.stringify(payload));

    return this.http.post<any>(Constants.endPoint(this.apiPath, Constants.FILTERS), payload).pipe(
      map(items => {
        return {
          ...items,
          rows: items?.rows?.map(item => {
            return ({
              ...item,
              createAt: moment(item.createAt).format('DD/MM/YYYY'),
              updatedAt: moment(item.updatedAt).format('DD/MM/YYYY')
            })
          })
        }
      }),
      catchError(this.handleError)
    );
  }



  findByIdWithIncludes(id: number, attributes: string[], includes?: Include[]): Observable<T> {
    includes = (includes) ? includes : [];
    attributes = (attributes) ? attributes : [];
    const payload = { id, attributes, includes};
    return this.http.post<T>(Constants.endPoint(this.apiPath, Constants.FIND_BY_ID_INCLUDES), payload).pipe(
      map(items => items),
      catchError(this.handleError)
    );
  }



  getById(id: number): Observable<T> {
    return this.http.get(`${Constants.endPoint(this.apiPath, Constants.FIND_BY_ID)}/${id}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

  getBySlug(slug: string): Observable<T> {
    return this.http.get(`${Constants.endPoint(this.apiPath, Constants.FIND_BY_SLUG)}/${slug}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

  create(resource: T): Observable<T> {
    return this.http.post(Constants.endPoint(this.apiPath, Constants.CREATE), resource).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

  update(resource: T): Observable<T> {
    console.log('update', `${Constants.endPoint(this.apiPath, Constants.UPDATE)}/${resource.id}`);
    return this.http.put(`${Constants.endPoint(this.apiPath, Constants.UPDATE)}/${resource.id}`, resource).pipe(
      map(() => resource),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${Constants.endPoint(this.apiPath, Constants.DELETE)}/${id}`).pipe(
      map(() => null),
      catchError(this.handleError)
    );
  }

  deleteMany(ids: number[]): Observable<any> {
    return this.http.post(Constants.endPoint(this.apiPath, Constants.DELETE_MANY), ids).pipe(
      map(() => null),
      catchError(this.handleError)
    );
  }

  protected jsonDataToResources(jsonData: any): T[] {
    const resources: T[] = [];
    jsonData.forEach(resource => resources.push(this.jsonDataToResourceFn(resource)));
    return resources;
  }

  protected jsonDataToResource(jsonData: any): T {
    return this.jsonDataToResourceFn(jsonData);
  }

  protected handleError(error: any): Observable<any> {
    console.log('ERRO NA REQUISIÇÃO => ', error);
    return throwError(error);
  }

}
