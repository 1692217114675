import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/store/auth.state';
import { TOKEN_TYPE } from 'src/app/auth/models/auth.interface';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorGuard implements CanActivate {

  constructor(private store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const tokenType = this.store.selectSnapshot(AuthState.tokenType);

      if (tokenType !== TOKEN_TYPE.TWO_FACTOR) { this.store.dispatch(new Navigate(['auth/signin'])); }

      return true;  }

}
