import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Plan } from 'src/app/auth/models/auth.interface';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Address } from '../../../auth/models/auth.interface';

@Injectable({
  providedIn: 'root'
})

export class AddressService extends GenerichttpService<Address> {

  constructor(protected injector: Injector) {
    super(Constants.ADDRESS.toString(), injector, Address.fromJson);
  }

  listCountries(): Observable<any> {
    return this.http.get(`${Constants.endPoint(this.apiPath, '/listCountries')}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }

}
