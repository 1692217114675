import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constantes/common.constante';
import { catchError } from 'rxjs/operators';
import { abandonCartProps, conversionProps } from 'src/app/tracking/models/tracking.interface';

@Injectable({
  providedIn: 'root'
})

export class RdService {

  constructor(private http: HttpClient) { }

  createContact(user: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'rds/createContact', user)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  updateContact(user: any, email: string): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + `rds/updateContact/${email}`, user)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  abandonCart(payload: abandonCartProps): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'rds/cartAbandoned', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  conversion(payload: conversionProps): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'rds/conversion', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  findContact(email: string): Observable<any> {
    return this.http.get(Constants.BASE_URL + `rds/findbyemail/${email}`)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }
}
