import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { StoreModule } from './store/store.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { GlobalErrorHandler } from './core/errors/global-error-handlers';
import { JwtInterceptor } from './core/guards/jwt.interceptor';
import { ServerErrorInterceptor } from './core/errors/server-error.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormlyModule.forRoot(),
    StoreModule,
    BrowserModule,
    AppRoutingModule,
    EditorModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    CoreModule,
    MatSnackBarModule,
    FormlyMaterialModule,
    MatIconModule,
    DeviceDetectorModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,

  ],
  providers: [
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: 'googleTagManagerId',  useValue: 'GTM-T5Z5HDD'}
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
