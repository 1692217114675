import { AuthState } from '../auth/store/auth.state';
import { BULARIO_STORE_CONFIG } from './bulario.config';
import { CALCULADORA_STORE_CONFIG } from './calc-store.config';
import { SITE_STORE_CONFIG } from './site-store.config';
import { BreadCrumbState } from '../shared/features/breadcrumb/store/breadcrumb.state';
import { FaqState } from '../dashboard/faq/faq-items/store/faq.state';

export const STORE_CONFIG = [
  BreadCrumbState,
  AuthState,
  FaqState,
  ...BULARIO_STORE_CONFIG,
  ...CALCULADORA_STORE_CONFIG,
  ...SITE_STORE_CONFIG,
];
