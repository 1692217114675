import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Constants } from '../../constantes/common.constante';
import { Autorization } from 'src/app/auth/models/auth.interface';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentAutorizationSubject: BehaviorSubject<Autorization>;
  public currentAutorization: Observable<Autorization>;
  errorData: {};
  redirectUrl: string;

  constructor(private http: HttpClient, private router: Router) {
    // this.currentAutorizationSubject = new BehaviorSubject<Autorization>(JSON.parse(localStorage.getItem('currentAutorization')));
    this.currentAutorization = this.currentAutorizationSubject.asObservable();
  }

  public get currentAutorizationValue(): Autorization {
    return this.currentAutorizationSubject.value;
  }

  login(username: string, password: string, deviceId: string) {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers };
    const body = { username, password, deviceOS: 'web' };

    return this.http.post(Constants.LOGIN, body, options).pipe(map(autorization => {
      if (autorization) {
        localStorage.setItem('currentAutorization', JSON.stringify(autorization));
        this.currentAutorizationSubject.next(autorization);
      }

      return autorization;
    }));
  }

  isLoggedIn() {
    if (localStorage.getItem('currentAutorization')) {
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem('currentAutorization');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
