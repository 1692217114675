import { BaseResource } from 'src/app/formly/models/basersource.model';

export class Icon extends BaseResource {
  id: number;
  content: string;
  isCompact: boolean;
  title: string;
  lastUpdate: string;
  status: number;
  length: number;
  elementId: number;
  element: Element;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): Icon {
    return Object.assign(new Icon(), jsonData);
  }
}

export const ATTRIBUTES_ICON = ['id', 'title', 'content', 'isCompact', 'elementId'];

export const TABLE_ICON = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'content', value: 'content', type: 'longText', showTable: true},
  { label: 'isCompact', value: 'isCompact', type: 'boolean'},
  { label: 'title', value: 'title', type: 'string', showTable: true},
  { label: 'lastUpdate', value: 'lastUpdate', type: 'date'},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'elementId', value: 'elementId', type: 'number'},

  { label: 'Elemento', value: 'element', field: 'name', type: 'object', showTable: true},

  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
