import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../constantes/common.constante';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { map } from 'rxjs/operators';
import { AuthStateModel } from 'src/app/auth/store/auth.statemodel';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private router: Router,
    private active: ActivatedRoute,
    private mediaObserver: MediaObserver,
    ) { }

  navigate(url: string){
    this.router.navigate([url]);
  }

  getErrorMessage(code: string) {
    console.log('Error formatado', Constants.ERROR_MESSAGES.find(item => item.code === code).message);
    return Constants.ERROR_MESSAGES.find(item => item.code === code).message;
  }

  isAuthorizeExpired(date: string) {
    if (!date) { return true; }
    const start = moment(date, 'YYYY-MM-DD');
    const end = moment(new Date().toISOString(), 'YYYY-MM-DD');
    const diff = moment.duration(start.diff(end)).asDays();
    return (diff >= 15) ? true : false;
  }


  getGateway(gateway: string) {
    if (gateway === 'i') { return 'IUGU'; }
    if (gateway === 'a') { return 'APPLE'; }
    if (gateway === 'g') { return 'GOOGLE'; }
  }

  isMobile(): Observable<boolean> {
    return this.mediaObserver.asObservable().pipe(map((change: MediaChange[]) => (change[0].mqAlias === 'xs') ? true : false));
  }

  validateSuspended(isFreeSubscription: boolean, sub, isFreemium: boolean) {

    if (isFreeSubscription) { return false; }
    // VERIFICA SE TEM ASSINATURA ATIVA
    if (sub && sub.status === 2) { return false; }

    // VERIFICA SE ESTA EM PERIODO DE TESTE
    if (isFreemium) { return false; }

    // VERIFICA SE A ASSINATURA É INATIVA OU CANCELADA
    if (!sub || sub.status !==2) { return true; }

    return true;
  }

  isVitalicio(auth: AuthStateModel) {
    if (!auth.isFreeSubscription) { return false; }
    const dataAtual = moment(new Date(), "DD/MM/YYYY");
    const dateFree = moment( new Date(auth.dateFreeSubscription), "DD/MM/YYYY");
    const diffFreeSubscription =  (!auth.dateFreeSubscription) ? 0 : dataAtual.diff(dateFree, 'days');
    return auth.isFreeSubscription && diffFreeSubscription <= 0 ? true : false;
  }

  isFreemium(auth: AuthStateModel): boolean {
    const dataAtual = moment(new Date(), "DD/MM/YYYY");
    const dataCadastro = moment( new Date(auth.dateJoined), "DD/MM/YYYY");
    const diff = dataAtual.diff(dataCadastro, 'days');
    const diasRestantes = 7 - diff;
    return diasRestantes >= 0 ? true : false;
  }

  getDiasRestantes(dateJoined): number {
    const dataAtual = moment(new Date(), "DD/MM/YYYY");
    const dataCadastro = moment( new Date(dateJoined), "DD/MM/YYYY");
    const diff = dataAtual.diff(dataCadastro, 'days');
    return 7 - diff;
  }


  isSuspendSubscription(isFreeSubscription:boolean, dateJoined: any, sub: any, dateFreeSubscription: any) {

    const dataAtual = moment(new Date(), "DD/MM/YYYY");
    const dataCadastro = moment( new Date(dateJoined), "DD/MM/YYYY");
    const dateFree = moment( new Date(dateFreeSubscription), "DD/MM/YYYY");
    const diff = dataAtual.diff(dataCadastro, 'days');
    const diasRestantes = 7 - diff;
    let isFreemium = (diasRestantes >= 0) ? true : false;

    // SE DATEFREESUB FOR NULL ENTENDE-SE QUE DEVE LIBERAR.
    // SE DATEFREESUB TIVER ALGUMA DATA SÓ LIBERA MEDIANTE O CALCULO
    const diffFreeSubscription =  (!dateFreeSubscription) ? 0 : dataAtual.diff(dateFree, 'days');
    console.log('**** diffFreeSubscription', diffFreeSubscription);

    //SE É VITALICIO ENTAO SUSPENSO = FALSO
    if (isFreeSubscription && diffFreeSubscription <= 0) { return false; }

    // SE TEM ASSINATURA ATIVA  ENTAO SUSPENSO = FALSO
    if (sub && sub.status === 2) { return false; }

    // SE É FREEMIUM  ENTAO SUSPENSO = FALSO
    if (isFreemium) { return false; }

    // SE A ASSINATURA É INATIVA OU CANCELADA ENTAO SUSPENSO = TRUE
    if (!sub || sub.status !==2) { return true; }

    return true;
  }

}
