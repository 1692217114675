import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { BularSubClass } from 'src/app/dashboard/bulario/models/bular.model';

@Injectable({
  providedIn: 'root'
})

export class BularSubClassService extends GenerichttpService<BularSubClass> {

  constructor(protected injector: Injector) {
    super(Constants.BULAR_SUBLCASSES.toString(), injector, BularSubClass.fromJson);
  }

}
