import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { CalcItem } from '../../../dashboard/calculators/models/calc-items.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CalcItemService extends GenerichttpService<CalcItem> {

  constructor(protected injector: Injector) {
    super(Constants.CALCULATORS_ITEMS.toString(), injector, CalcItem.fromJson);
  }

  findWithAssociations(id: number): Observable<CalcItem[]> {
    console.log('ENTROU EM FIND ASSOCIATIONS', id);
    return this.http.get(`${Constants.endPoint(this.apiPath, '/findWithAssociations')}/${id}`).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );
  }


}
