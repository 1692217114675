import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/store/auth.state';
import { Navigate } from '@ngxs/router-plugin';
import { TOKEN_TYPE } from '../../../auth/models/auth.interface';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private store: Store) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    if (!isAuthenticated) {
      this.store.dispatch(new Navigate(['auth/signin']));
    }
    return isAuthenticated;
  }

}

