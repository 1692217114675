import { Filter, Order } from 'src/app/formly/models/filters.model';
import { Element } from './../models/element.model';

export class LoadItems {
  static readonly type = '[Element] LoadItems';
  constructor(public limit: number, public offset: number, public filters: Filter[], public orders: Order[]) {}
}

export class FindItem {
  static readonly type = '[Element] FindItem';
  constructor(public id: number) {}
}

export class AddEditItem {
  static readonly type = '[Element] AddEditItem';
  constructor(public item: Element) {}
}

export class EditItem {
  static readonly type = '[Element] EditItem';
  constructor(public item: Element) {}
}

export class SetLoading {
  static readonly type = '[Element] SetLoading';
  constructor(public loading: boolean) {}
}

export class SetError {
  static readonly type = '[Element] SetLoading';
  constructor(public loading: boolean) {}
}

export class NavigateToPage {
  static readonly type = '[Element] NavigateToPage';
  constructor(public url: string) {}
}

export class SetSuccess {
  static readonly type = '[Element] SetSuccess';
  constructor(public msg: string) {}
}

export class SetItemActive {
  static readonly type = '[Element] SetItemActive';
  constructor(public item: Element) {}
}

export class SetItemsSelected {
  static readonly type = '[Element] SetItemsSelected';
  constructor(public items: Element[]) {}
}

export class DeleteItems {
  static readonly type = '[Element] DeleteItems';
  constructor(public items: Element[]) {}
}

export class DeleteItem {
  static readonly type = '[Element] DeleteItem';
  constructor(public item: Element) {}
}


