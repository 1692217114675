import { DeviceGuard } from './device.guard';
import { TwoFactorGuard } from './two-factor.guard';
import { AuthGuard } from './auth.guard';
import { SubscriberGuard } from './subscriber.guard';

export const GUARD_SERVICES = [
  AuthGuard,
  DeviceGuard,
  TwoFactorGuard,
  SubscriberGuard
];
