import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Icon } from 'src/app/dashboard/icons/models/icon.model';

@Injectable({
  providedIn: 'root'
})

export class IconService extends GenerichttpService<Icon> {

  constructor(protected injector: Injector) {
    super(Constants.ICON.toString(), injector, Icon.fromJson);
  }

}
