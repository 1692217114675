import { AuthService } from './auth.service';
import { AuthenticationService } from './authentication.service';
import { DeviceService } from './device-detector.service';
import { SubItemService } from './subitem.service';
import { PlanService } from './plan.service';
import { AddressService } from './address.service';
import { CountryService } from './country.service';
import { CEPService } from './cep.service';

export const AUTH_SERVICES = [
  AuthService,
  AuthenticationService,
  DeviceService,
  SubItemService,
  PlanService,
  AddressService,
  CountryService,
  CEPService
];
