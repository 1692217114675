import { BaseResource } from 'src/app/formly/models/basersource.model';

export class Site extends BaseResource {
  id: number;
  title: string;
  link: string;
  siteCategory: any;
  siteCategoryId: number;
  subtitle: string;
  introduction: string;
  description: string;
  image: string;
  imageBase64: string;
  order: number;
  isPublished: boolean;
  type: string;
  section: string;
  icon: string;

  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): Site {
    return Object.assign(new Site(), jsonData);
  }
}

export const TABLE_SITE_CONTENT = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'title', value: 'title', type: 'string', showTable: true},
  { label: 'introduction', value: 'introduction', type: 'string', showTable: true},
  { label: 'description', value: 'description', type: 'longText'},
  { label: 'isPublished', value: 'isPublished', type: 'boolean'},
  { label: 'isPost', value: 'isPost', type: 'boolean'},
  { label: 'order', value: 'order', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
