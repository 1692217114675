import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { BularConfig } from 'src/app/dashboard/bulario/models/bular.model';

@Injectable({
  providedIn: 'root'
})

export class BularConfigService extends GenerichttpService<BularConfig> {

  constructor(protected injector: Injector) {
    super(Constants.BULAR_CONFIG.toString(), injector, BularConfig.fromJson);
  }

}
