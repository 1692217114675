import { SiteState } from '../site/store/site.state';
import { GroupClassState } from '../dashboard/classes/store/groupclass.state';
import { GroupContentState } from '../dashboard/groups/store/groupcontent.state';
import { ElementState } from '../dashboard/elements/store/element.state';
import { IconState } from '../dashboard/icons/store/icon.state';


export const SITE_STORE_CONFIG = [
  SiteState,
  GroupClassState,
  GroupContentState,
  ElementState,
  IconState
];
