import { SetFreemium } from './../store/auth.actions';
import { Injectable } from '@angular/core';

import {
  Logout, SignInMailPassword, ValidateTwoFactor,
  ValidateDevice, DeviceFind, LoadDeviceLocal,
  Forgot, ForgotConfirm, LoadSubItems, LoadSignupPlans, InitAuthorize, ClearGroup } from '../store/auth.actions';

import { AuthState } from '../store/auth.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';

import {
  SignUp, LoadSignupClasses, SignupPlanClasses, LoadSignup,
  ResendFactor, LoadProfile, UpdateProfile, SendMail,
  ClearError, SetLogin, SetAuthorize } from '../store/auth.actions';

import { SignupPlanClassesModel, SignUpModel } from '../models/auth.interface';
import { SetGroup } from '../store/auth.actions';

@Injectable({ providedIn: 'root' })

export class AuthFacade {

  public constructor(private store: Store) { }

  @Select(AuthState.twoFactor) twoFactor$: Observable<any>;
  @Select(AuthState.signin) signin$: Observable<any>;
  @Select(AuthState.signup) signup$: Observable<any>;
  @Select(AuthState.assinar) assinar$: Observable<any>;
  @Select(AuthState.auth) auth$: Observable<any>;
  @Select(AuthState.token) token$: Observable<string>;
  @Select(AuthState.tokenType) tokenType$: Observable<string>;
  @Select(AuthState.error) error$: Observable<string>;
  @Select(AuthState.success) success$: Observable<string>;

  @Select(AuthState.deviceFinded) deviceFinded$: Observable<any>;
  @Select(AuthState.signUpPlanClasses) signUpPlanClasses$: Observable<SignupPlanClassesModel>;
  @Select(AuthState.signUpPlans) signUpPlans$: Observable<SignupPlanClassesModel>;
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.profile) profile$: Observable<any>;
  @Select(AuthState.loading) loading$: Observable<boolean>;

  public signinMailPassword(payload: any) {
    this.store.dispatch(new SignInMailPassword(payload));
  }

  public clearError() {
    this.store.dispatch(new ClearError());
  }

  public sendMail(payload: any) {
    this.store.dispatch(new SendMail(payload));
  }

  public setLogin(payload: any) {
    this.store.dispatch(new SetLogin(payload));
  }

  public setAuthorize(payload: any) {
    this.store.dispatch(new SetAuthorize(payload));
  }

  public setFreemium() {
    this.store.dispatch(new SetFreemium());
  }


  public signUp(payload: SignUpModel) {
    this.store.dispatch(new SignUp(payload));
  }

  public validateTwoFactor(payload: any) {
    this.store.dispatch(new ValidateTwoFactor(payload));
  }

  public loadProfile() {
    this.store.dispatch(new LoadProfile());
  }

  public validateDevice(payload: any) {
    this.store.dispatch(new ValidateDevice(payload));
  }

  public deviceFind() {
    this.store.dispatch(new DeviceFind());
  }

  public loadDeviceLocal() {
    this.store.dispatch(new LoadDeviceLocal());
  }


  public resendFactor() {
    this.store.dispatch(new ResendFactor());
  }

  public loadSubItems() {
    this.store.dispatch(new LoadSubItems());
  }

  public updateProfile(profile: any) {
    this.store.dispatch(new UpdateProfile(profile));
  }

  public setGroup(group: any) {
    this.store.dispatch(new SetGroup(group));
  }

  public clearGroup() {
    this.store.dispatch(new ClearGroup());
  }

  public loadSignupPlans(identifier: string) {
    this.store.dispatch(new LoadSignupPlans(identifier));
  }

  public loadSignupClasses(identifier: string) {
    this.store.dispatch(new LoadSignupClasses(identifier));
  }

  public loadSignup() {
    this.store.dispatch(new LoadSignup());
  }

  public logout() {
    this.store.dispatch(new Logout());
  }

  public signupPlanClasses(subscription: SignupPlanClassesModel) {
    this.store.dispatch(new SignupPlanClasses(subscription));
  }

  public initAuthorize() {
    this.store.dispatch(new InitAuthorize());
  }

  public forgot(payload: any) {
    this.store.dispatch(new Forgot(payload));
  }

  public forgotConfirm(token: string, password: string, passwordConfirm: string) {
    this.store.dispatch(new ForgotConfirm(token, password, passwordConfirm));
  }

  public navigateTo(url: string) {
    this.store.dispatch(new Navigate([url]));
  }

}
