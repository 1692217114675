import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './groupclass.actions';

import { Navigate } from '@ngxs/router-plugin';
import { GroupClass, ATTRIBUTES_CLASS } from '../models/groupclass.model';
import { GroupClassService } from '../../../core/services/contents/groupclasse.service';
import { GroupContent, ATTRIBUTES_GROUPCONTENT } from '../../groups/models/groupcontent.model';
import { GroupContentService } from '../../../core/services/contents/groupcontent.service';
import { Order } from 'src/app/formly/models/filters.model';

export class GroupClassStateModel extends NgxsEntityStateModel<GroupClass> {}

@State<GroupClassStateModel>({
  name: 'groupclass',
  defaults: GroupClassStateModel.InitialState()
})

@Injectable()
export class GroupClassState {

  constructor(private classeService: GroupClassService, private groupService: GroupContentService) { }

  @Selector()
  static entities(state: GroupClassStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: GroupClassStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: GroupClassStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: GroupClassStateModel) {
    return state;
  }

  @Selector()
  static items(state: GroupClassStateModel) {
    return state.items;
    // return Object.keys(state.entities).map(id => state.entities[id]);
  }

  @Selector()
  static count(state: GroupClassStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: GroupClassStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: GroupClassStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: GroupClassStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: GroupClassStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: GroupClassStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  async findItem(ctx: StateContext<GroupClassStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);
    const orders: Order[] = [{ field: 'name', value: 1 }];
    try {
      if (action.id === 0) {

        const groups = await this.groupService.listAll(ATTRIBUTES_GROUPCONTENT).pipe(take(1)).toPromise();
        console.log('*** GROUPS ****', groups);
        const item: any = { id: 0, groups: [] };
        NgxsEntityAdapter.setItemSelected(ctx, item);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setDualList(ctx, groups, []);
        return of(item);

      } else {
        const includes = [{attributes: ATTRIBUTES_GROUPCONTENT , model: 'GroupContent', as: 'groups'}];

        const classe: GroupClass = await this.classeService.findByIdWithIncludes(action.id, ATTRIBUTES_CLASS, includes)
        .pipe(take(1)).toPromise();

        const filters = [{ fieldName: 'id', matchMode: 'notIn', fieldValue: classe.groups.map(p => p.id.toString()) }];

        const groupsIN: any = await this.groupService.filters(1000, 0, [], [], [], filters, ATTRIBUTES_GROUPCONTENT)
        .pipe(take(1)).toPromise();

        console.log('*** CLASSE ***', classe);
        console.log('*** GROUPS IN ***', groupsIN);

        NgxsEntityAdapter.setItemSelected(ctx, classe);
        NgxsEntityAdapter.setLoading(ctx, false);
        NgxsEntityAdapter.setDualList(ctx, groupsIN.rows, classe.groups);

        return of(classe);
      }
    } catch(err) {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }

}


  @Action(LoadItems)
  loadItems(ctx: StateContext<GroupClassStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    return this.classeService.list()
    .pipe(
        tap((res: any[]) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res, res.length);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<GroupClassStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);

    if (action.item.id === 0) {
      return this.classeService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/classes/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.classeService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/classes/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }
   }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<GroupClassStateModel>, action: DeleteItem) {
     return this.classeService.delete(action.item.id).pipe(take(1)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res);
       ctx.dispatch(new Navigate(['dashboard/classes/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     });
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<GroupClassStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.classeService.deleteMany(action.items.map(i => i.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/elements/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<GroupClassStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<GroupClassStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
