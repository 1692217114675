import { BularBulasService } from './bular-bulas.service';
import { BularConfigService } from './bular-config';
import { BularLabService } from './bular-labs';
import { BularClassService } from './bular-classes';
import { BularSubClassService } from './bular-subclasses.service';
import { BularEspecService } from './bular-espec.service';

export const BULAR_SERVICES = [
  BularBulasService,
  BularConfigService,
  BularLabService,
  BularClassService,
  BularSubClassService,
  BularEspecService
];
