import { BaseResource } from 'src/app/formly/models/basersource.model';

export class BularBula extends BaseResource {
  id: number;
  name: string;
  slug: string;
  substance: string;
  laboratoryId: number;
  isGeneric: boolean;
  isActive: boolean;
  isPopularPharmacy: boolean;
  recipient: number;

  tradeName: string;
  indications: string;
  warnings: string;
  lactationText: string;
  dosageAdults: string;
  dosageChildren: string;
  contraIndications: string;
  gestationText: string;
  presentation: string;
  recipientType: string;

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularBula {
    return Object.assign(new BularBula(), jsonData);
  }
}

export const TABLE_BULAR_BULA = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'Nome Comercial', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string'},
  { label: 'Substância', value: 'substance', type: 'string', showTable: true},
  { label: 'laboratoryId', value: 'laboratoryId', type: 'number'},
  { label: 'recipient', value: 'recipient', type: 'number'},
  { label: 'gestation', value: 'recipient', type: 'number'},

  { label: 'isFree', value: 'isFree', type: 'boolean'},
  { label: 'Genérico', value: 'isGeneric', type: 'boolean' , showTable: true},
  { label: 'Farmácia Popular', value: 'isPopularPharmacy', type: 'boolean', showTable: true},

  { label: 'tradeName', value: 'tradeName', type: 'longtext'},
  { label: 'indications', value: 'indications', type: 'longtext'},
  { label: 'warnings', value: 'warnings', type: 'longtext'},
  { label: 'lactationText', value: 'lactationText', type: 'longtext'},
  { label: 'dosageAdults', value: 'dosageAdults', type: 'longtext'},
  { label: 'dosageChildren', value: 'dosageChildren', type: 'longtext'},
  { label: 'contraIndications', value: 'contraIndications', type: 'longtext'},
  { label: 'gestationText', value: 'gestationText', type: 'longtext'},
  { label: 'presentation', value: 'presentation', type: 'longtext'},
  { label: 'recipientType', value: 'recipientType', type: 'longtext'},

  { label: 'status', value: 'status', type: 'number', showTable: true},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];


export class BularLabs extends BaseResource {
  id: number;
  name: string;
  slug: string;

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularLabs {
    return Object.assign(new BularLabs(), jsonData);
  }
}

export const TABLE_BULAR_LABS = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},

  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class BularConfig extends BaseResource {
  id: number;
  name: string;
  slug: string;
  icon: string;
  iconBase64: string;
  color: string;
  isFree: boolean;
  hasFilter: boolean;
  groupClassId: number;
  order: number;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularConfig {
    return Object.assign(new BularConfig(), jsonData);
  }
}

export const TABLE_BULAR_CONFIG = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'icon', value: 'icon', type: 'string'},
  { label: 'iconBase64', value: 'iconBase64', type: 'string'},
  { label: 'color', value: 'color', type: 'string'},
  { label: 'isFree', value: 'isFree', type: 'boolean'},
  { label: 'hasFilter', value: 'hasFilter', type: 'boolean'},
  { label: 'groupClassId', value: 'groupClassId', type: 'number'},
  { label: 'Ordem', value: 'order', type: 'number', showTable: true},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class BularEspecialidade extends BaseResource {
  id: number;
  name: string;
  slug: string;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularEspecialidade {
    return Object.assign(new BularEspecialidade(), jsonData);
  }
}

export const TABLE_BULAR_ESPECIALIDADE = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},

  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class BularSubClass extends BaseResource {
  id: number;
  name: string;
  slug: string;
  therapeuticClassId: number;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularSubClass {
    return Object.assign(new BularSubClass(), jsonData);
  }
}

export const TABLE_BULAR_SUBCLASS = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'therapeuticClassId', value: 'therapeuticClassId', type: 'number'},

  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class BularClass extends BaseResource {
  id: number;
  name: string;
  slug: string;
  specialityId: number;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): BularClass {
    return Object.assign(new BularClass(), jsonData);
  }
}

export const TABLE_BULAR_CLASS = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'specialityId', value: 'specialityId', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
