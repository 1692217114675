import { BaseResource } from 'src/app/formly/models/basersource.model';
import { GroupContent } from 'src/app/dashboard/groups/models/groupcontent.model';

export class CalcItem extends BaseResource {
  id: number;
  name: string;
  slug: string;
  filename: string;
  subtitle: string;
  isVisible: string;
  content: string;
  contentHash: string;
  groups: GroupContent[];

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): CalcItem {
    return Object.assign(new CalcItem(), jsonData);
  }
}

export const TABLE_CALC_ITEMS = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'Nome', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string'},
  { label: 'filename', value: 'filename', type: 'string'},
  { label: 'subtitle', value: 'subtitle', type: 'string'},
  { label: 'Visível no Grupo', value: 'isVisible', type: 'boolean'},
  { label: 'content', value: 'content', type: 'string'},
  { label: 'contentHash', value: 'contentHash', type: 'string'},

  { label: 'Grupos', value: 'groups', field: 'name', type: 'objectMany', showTable: true},

  { label: 'status', value: 'status', type: 'number'},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
