import { BaseResource } from 'src/app/formly/models/basersource.model';

export class AntiMicrobialRenalAdjustGroup extends BaseResource {
  id: number;
  name: string;
  slug: string;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialRenalAdjustGroup {
    return Object.assign(new AntiMicrobialRenalAdjustGroup(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_RENALADJUSTGROUP = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class AntiMicrobialRenalAdjust extends BaseResource {
  id: number;
  name: string;
  slug: string;
  renalGroupId: number;
  isFree: boolean;

  renalFunction: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialRenalAdjust {
    return Object.assign(new AntiMicrobialRenalAdjust(), jsonData);
  }
}


export class AntiMicrobialInfeccoes extends BaseResource {
  id: number;
  name: string;
  slug: string;
  type: number;
  isFree: boolean;

  etiologicalAgent: string;
  firstChoice: string;
  secondChoice: string;
  comments: string;

  antiMicrobialCategoryId: number;

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialInfeccoes {
    return Object.assign(new AntiMicrobialInfeccoes(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_INFECCOES = [

  { label: 'Id', value: 'id', type: 'number', showTable: true},
  { label: 'Diagnóstico', value: 'name', type: 'string', showTable: true},
  { label: 'Slug', value: 'slug', type: 'string', showTable: false},
  { label: 'Tipo', value: 'type', type: 'number', showTable: true},
  { label: 'Categoria', value: 'category', field: 'name', type: 'object', showTable: true},

  { label: 'Agende Estiológico', value: 'etiologicalAgent', type: 'longtext', },
  { label: 'Primeira Escolha', value: 'firstChoice', type: 'longtext'},
  { label: 'Segunda Escolha', value: 'secondChoice', type: 'longtext'},
  { label: 'Grupo Class Id', value: 'groupClassId', type: 'longtext'},
  { label: 'Comentários', value: 'comments', type: 'longtext'},

  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class AntiMicrobialExtras extends BaseResource {
  id: number;
  name: string;
  slug: string;
  isFree: boolean;
  htmlSource: string;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialExtras {
    return Object.assign(new AntiMicrobialExtras(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_EXTRAS = [

  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'name', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'htmlSource', value: 'htmlSource', type: 'longtext'},
  { label: 'status', value: 'status', type: 'number'},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class AntiMicrobialConfig extends BaseResource {
  id: number;
  name: string;
  slug: string;
  icon: string;
  iconBase64: string;
  color: string;
  isFree: boolean;
  hasFilter: boolean;
  groupClassId: number;
  order: number;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialConfig {
    return Object.assign(new AntiMicrobialConfig(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_CONFIG = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'nome', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string', showTable: true},
  { label: 'icon', value: 'icon', type: 'string'},
  { label: 'iconBase64', value: 'iconBase64', type: 'string'},
  { label: 'color', value: 'color', type: 'string'},
  { label: 'isFree', value: 'isFree', type: 'boolean'},
  { label: 'hasFilter', value: 'hasFilter', type: 'boolean'},
  { label: 'groupClassId', value: 'groupClassId', type: 'number'},
  { label: 'ordem', value: 'order', type: 'number', showTable: true},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];


export class AntiMicrobialCategory extends BaseResource {
  id: number;
  name: string;
  slug: string;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialCategory {
    return Object.assign(new AntiMicrobialCategory(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_CATEGORY = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'Nome', value: 'name', type: 'string', showTable: true},
  { label: 'Slug', value: 'slug', type: 'string', showTable: true},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];



export class AntiMicrobialProfilaxia extends BaseResource {
  id: number;
  name: string;
  slug: string;
  firstChoice: number;
  secondChoice: boolean;
  duration: string;

  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): AntiMicrobialProfilaxia {
    return Object.assign(new AntiMicrobialProfilaxia(), jsonData);
  }
}

export const TABLE_ANTIMICROBIAL_PROFILAXIA = [

  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'Cirurgia', value: 'name', type: 'string', showTable: true},
  { label: 'slug', value: 'slug', type: 'string'},
  { label: 'Primeira Escolha', value: 'firstChoice', type: 'string',  showTable: true},
  { label: 'Segunda Escolha', value: 'secondChoice', type: 'string',  showTable: true},
  { label: 'Duração', value: 'duration', type: 'string', showTable: true},

  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
