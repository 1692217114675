import { BaseResource } from 'src/app/formly/models/basersource.model';

export class FaqItem extends BaseResource {
  id: number;
  question: string;
  answer: string;
  isFeatured: boolean;
  slug:string;
  status: number;
  length: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromJson(jsonData: any): FaqItem {
    return Object.assign(new FaqItem(), jsonData);
  }
}

export const TABLE_FAQ_ITEM = [
  { label: 'id', value: 'id', type: 'number', showTable: true},
  { label: 'Pergunta', value: 'question', type: 'string', showTable: true},
  { label: 'Destaque', value: 'isFeatured', type: 'string', showTable: true},
  { label: 'Resposta', value: 'answer', type: 'string'},
  { label: 'icon', value: 'icon', type: 'string'},
  { label: 'iconBase64', value: 'iconBase64', type: 'string'},
  { label: 'color', value: 'color', type: 'string'},
  { label: 'isFree', value: 'isFree', type: 'boolean'},
  { label: 'hasFilter', value: 'hasFilter', type: 'boolean'},
  { label: 'groupClassId', value: 'groupClassId', type: 'number'},
  { label: 'order', value: 'order', type: 'number'},
  { label: 'status', value: 'status', type: 'number', showTable: false},
  { label: 'length', value: 'length', type: 'number'},
  { label: 'createAt', value: 'createAt', type: 'date'},
  { label: 'updatedAt', value: 'updatedAt', type: 'date'},
  { label: 'deletedAt', value: 'deletedAt', type: 'date'}
];
