
import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { Plan, Country } from 'src/app/auth/models/auth.interface';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class CEPService extends GenerichttpService<any> {

  constructor(protected injector: Injector) {
    super(Constants.ADDRESS.toString(), injector, Country.fromJson);
  }

  getAddress(cep: string): Observable<any> {
    return this.http.get<any>(`viacep.com.br/ws/${cep}/json`).pipe(
      catchError(this.handleError)
    );
  }

}
