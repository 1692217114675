import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../constantes/common.constante';
import { GenerichttpService } from '../generic/generichttp.service';
import { AntiMicrobialRenalAdjustGroup } from 'src/app/dashboard/microbial/models/microbial.model';

@Injectable({
  providedIn: 'root'
})

export class AntimicrobialRenalAdjustGroupService extends GenerichttpService<AntiMicrobialRenalAdjustGroup> {

  constructor(protected injector: Injector) {
    super(Constants.ANTIMICROBIAL_RENALADJUSTGROUP.toString(), injector, AntiMicrobialRenalAdjustGroup.fromJson);
  }

}
