import { UserToken, AuthDevice, SignupPlanClassesModel, SignUpModel } from '../../../auth/models/auth.interface';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../../constantes/common.constante';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }

  setTokenSite() {
    localStorage.setItem('token_site', 'eyJhbGciOiJIUzI1NiJ9.eyJjcmVkZW50aWFsIjoiZmVybmFuZG9yYWxoYUBnbWFpbC5jb20iLCJ0eXBlIjoiVE9LRU4ifQ.fH0w3dQkgZCfAi9fu8kuHf73PJLzTEyM3HGPjaZ2eCQ');
  }

  sendMail(payload: any): Observable<any> {
    return this.http.post<UserToken>(Constants.BASE_URL + 'mail/post', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  signinMailPassword(userSignin: any): Observable<any> {
    console.log('userSignin', userSignin);
    return this.http.post<any>(Constants.BASE_URL + 'user/simpleLogin', userSignin)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  validateSubscription(token: string, username: string): Promise<any> {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ username })
    };

    return fetch(Constants.BASE_URL + 'user/validateSubscription', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        return data;
      });
  }


  createFactor(payload: any): Observable<any> {
    console.log('CREATE FACTOR PAYLAOD', payload);
    return this.http.post<any>(Constants.BASE_URL + 'security/createfactor', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  signinTwoFactor(payload: any, token: string): Observable<UserToken> {
    console.log('userSignin TWOFACTOR', payload);
    return this.http.post<UserToken>(Constants.BASE_URL + 'security/twoFactor',
      { username: payload.username, code: payload.code, deviceId: payload.deviceId },
      { headers: new HttpHeaders().set('Authorization', token) })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  forgot(email: string, phoneCode: string, phoneNumber: string): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'security/forgot', { email, phoneCode, phoneNumber });
  }

  forgotConfirm(token: string, password: string, passwordConfirm: string): Observable<any> {
    const payload = {
      password,
      confirmationPassword: passwordConfirm
    }
    console.log('PAYLOAD', payload);
    return this.http.post<any>(Constants.BASE_URL + 'security/rewrite?token=' + token, payload);
  }

  findProfile(email: string): Observable<any> {
    return this.http.get(Constants.BASE_URL + 'security/findProfile/' + email)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deviceFindByID(id: any, token: string): Promise<AuthDevice> {
    return this.http.get<AuthDevice>(Constants.BASE_URL + 'device/find/' + id,
      { headers: new HttpHeaders().set('Authorization', token) })
      .pipe(
        take(1),
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      ).toPromise();
  }

  deviceFindByKey(uuid: any, token: string): Observable<AuthDevice> {
    return this.http.get<AuthDevice>(Constants.BASE_URL + 'device/findByKey/' + uuid,
      { headers: new HttpHeaders().set('Authorization', token) })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  deviceCreate(payload: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'device/create', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  log(payload: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/log', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  subscribe(payload: SignupPlanClassesModel): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/subscribe', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  changeCard(payload: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/changecard', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }



  iuguActivate(subscriptionId: number): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'subscription/iugu/activate/' + subscriptionId, null)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  iuguSuspend(subscriptionId: number): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'subscription/iugu/suspend/' + subscriptionId, null)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  iuguChangePlan(subscriptionId: number, planIdentifier: string): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'subscription/iugu/changeplan/' + subscriptionId + '/' + planIdentifier, null)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  iuguChangePlanClasses(payload: any): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'subscription/iugu/changeplanclasses', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  validateDevice(email: string, device: AuthDevice): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/validatedevice', { email, device })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  signupUser(payload: SignUpModel, token?: string): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/simpleSignUp', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  resendFactor(email: string): Observable<any> {
    return this.http.post<any>(Constants.BASE_URL + 'user/resendfactor', { email })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }

  subItems(): Observable<any> {
    return this.http.get(Constants.BASE_URL + 'user/subItems')
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
  }


}
