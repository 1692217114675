import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxsEntityStateModel } from 'src/app/store/ngxs-entitystatemodel';
import { NgxsEntityAdapter } from 'src/app/store/ngxs-entityadapter';

import {
  NavigateToPage, LoadItems,
  FindItem, AddEditItem, DeleteItems, SetItemsSelected, DeleteItem } from './faq.actions';

import { Navigate } from '@ngxs/router-plugin';
import { FaqItem } from '../models/faq.model';
import { FaqService } from 'src/app/core/services/faq/faq.service';

export class FaqStateModel extends NgxsEntityStateModel<FaqItem> {}

@State<FaqStateModel>({
  name: 'faq',
  defaults: FaqStateModel.InitialState()
})

@Injectable()
export class FaqState {

  constructor(private faqItemService: FaqService) { }

  @Selector()
  static entities(state: FaqStateModel) {
    return state.entities;
  }

  @Selector()
  static dualListFrom(state: FaqStateModel) {
    return state.dualListFrom;
  }

  @Selector()
  static dualListTarget(state: FaqStateModel) {
    return state.dualListTarget;
  }

  @Selector()
  static itemState(state: FaqStateModel) {
    return state;
  }

  @Selector()
  static items(state: FaqStateModel) {
    return state.items;
    // return Object.keys(state.entities).map(id => state.entities[id]);
  }

  @Selector()
  static count(state: FaqStateModel) {
    return state.count;
  }

  @Selector()
  static itemSelected(state: FaqStateModel) {
    return state.itemSelected;
  }

  @Selector()
  static itemsSelected(state: FaqStateModel) {
    return state.itemsSelected;
  }

  @Selector()
  static success(state: FaqStateModel) {
    return state.success;
  }

  @Selector()
  static error(state: FaqStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: FaqStateModel) {
    return state.loading;
  }

  @Action(FindItem)
  findItem(ctx: StateContext<FaqStateModel>, action: FindItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    if (action.id === '0') {
      const item: any = { id: '0' };
      NgxsEntityAdapter.setItemSelected(ctx, item);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(item);
    } else {
      this.faqItemService.getBySlug(action.id).pipe(take(1)).toPromise()
      .then(item => {
          console.log('ITEM ', item);
          NgxsEntityAdapter.setItemSelected(ctx, item);
          NgxsEntityAdapter.setLoading(ctx, false);
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }
}


  @Action(LoadItems)
  loadItems(ctx: StateContext<FaqStateModel>, action: LoadItems) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, null);
    NgxsEntityAdapter.setError(ctx, null);

    return this.faqItemService.list()
    .pipe(
        tap((res: any) => {
          console.log('***** ITEMS ***', res);
          NgxsEntityAdapter.setItems(ctx, res, res.lenght);
          NgxsEntityAdapter.setLoading( ctx, false);
        }),
        catchError(err => {
          NgxsEntityAdapter.setError(ctx, err.message);
          NgxsEntityAdapter.setLoading(ctx, false);
          return of(err);
      })
    );
  }

  @Action(AddEditItem)
  addEditItem(ctx: StateContext<FaqStateModel>, action: AddEditItem) {
    NgxsEntityAdapter.setLoading(ctx, true);
    if (action.item.id === 0) {
      return this.faqItemService.create(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/faq/item/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    } else {
      return this.faqItemService.update(action.item).pipe(take(1)).toPromise()
      .then(res => {
        NgxsEntityAdapter.addItem(res, ctx);
        NgxsEntityAdapter.setLoading(ctx, false);
        ctx.dispatch(new NavigateToPage('dashboard/faq/item/lista'));
      })
      .catch(err => {
        NgxsEntityAdapter.setError(ctx, err.message);
        NgxsEntityAdapter.setLoading(ctx, false);
        return of(err);
      });
    }

   }

   @Action(DeleteItem)
   deleteItem(ctx: StateContext<FaqStateModel>, action: DeleteItem) {
     /* return this.faqItemService.deletePermWithRoles(FaqItemService.item)).toPromise()
     .then(res => {
       NgxsEntityAdapter.setLoading(ctx, false);
       NgxsEntityAdapter.setSuccess(ctx, res.decription);
       ctx.dispatch(new Navigate(['dashboard/elements/lista']));
     })
     .catch(err => {
       NgxsEntityAdapter.setError(ctx, err.message);
       NgxsEntityAdapter.setLoading(ctx, false);
       return of(err);
     }); */
   }

  @Action(DeleteItems)
  deleteItems(ctx: StateContext<FaqStateModel>, action: DeleteItems) {
    const state = ctx.getState();
   /*  return this.faqItemService.deleteMany(FaqItemService.items.m.id)).pipe(take(1)).toPromise()
    .then(res => {
      NgxsEntityAdapter.setLoading(ctx, false);
      ctx.dispatch(new Navigate(['dashboard/elements/lista']));
    })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
      return of(err);
    }); */
  }

  @Action(NavigateToPage)
  navigateToPage(ctx: StateContext<FaqStateModel>, action: NavigateToPage) {
    ctx.dispatch(new Navigate([action.url]));
  }

  @Action(SetItemsSelected)
  setItemsSelected(ctx: StateContext<FaqStateModel>, action: SetItemsSelected) {
    console.log('items selected', action.items);
    NgxsEntityAdapter.setItemsSelected(ctx, action.items);
  }

}
