import { BaseResource } from 'src/app/formly/models/basersource.model';
import { SiteComment } from './site-comment.model';

export class SitePost extends BaseResource {
  id: number;
  createAt: string;
  updatedAt: string;
  deletedAt: string;
  title: string;
  link: string;
  featured: boolean;
  siteCategory: any;
  siteCategoryId: number;
  subtitle: string;
  introduction: string;
  description: string;
  image: string;
  post: string;
  categoryId: number;
  comments: SiteComment[];
  category: {
    id: number;
    name: string;
  }

  static fromJson(jsonData: any): SitePost {
    return Object.assign(new SitePost(), jsonData);
  }
}
